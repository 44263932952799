import {
    CardContent, CardHeader,
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DashboardContainer, DashboardWidgetCard } from '../DashboardSimple/DashboardComponents';
import { useSelector, useDispatch } from 'react-redux';
import CrawlerInfo from './CrawlerInfo';
import StartFirstCrawl from './StartFirstCrawl';
import CrawlStatusPolling from './CrawlStatusPolling';
import { startCrawl, fetchCrawlStatus } from '../../actions/crawls';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    combinedPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    card: {
        'min-height': '1em'
    }
}));

function CrawlerControlPanel() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const workspaces = useSelector(state => state.workspaces);
    const lastCrawlId = workspaces?.current?.lastCrawls?.length ? workspaces.current.lastCrawls[0].id : null;

    const handleStartCrawl = () => {
        dispatch(startCrawl(workspaces.current.seqNumber)); // Assuming seqNumber is the workspace identifier
    };

    useEffect(() => {
        if (lastCrawlId) {
            dispatch(fetchCrawlStatus(lastCrawlId));
        }
    }, [lastCrawlId, dispatch]);

    return (
        <DashboardContainer>
            <DashboardWidgetCard className={classes.card}>
                <CardHeader title="Crawler Control Panel" />
                <CardContent className={classes.content}>
                    {lastCrawlId ? (
                        <>
                            <CrawlStatusPolling lastCrawlId={lastCrawlId} />
                            <CrawlerInfo
                                lastCrawlId={lastCrawlId}
                                onCrawlStart={handleStartCrawl}
                                crawlStarting={workspaces.current.crawlStarting}
                            />
                        </>
                    ) : (
                        <StartFirstCrawl onStart={handleStartCrawl}
                        crawlStarting={workspaces.current.crawlStarting} />
                    )}
                </CardContent>
            </DashboardWidgetCard>
        </DashboardContainer>
    );
}

export default CrawlerControlPanel;
