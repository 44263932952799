import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    root: {
        "display": "flex",
        "align-items": "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "8px",
        paddingBottom: "8px",
        position: "relative",
        "&:hover": {
            "text-decoration": "none",
            "background-color": "rgba(0, 0, 0, 0.04)"
        }

    }, avatar: {
        boxShadow: theme.shadows[1],
        "backgroundColor": "#327bc6",
        "padding": "8px",
        "border-radius": "10px",
        "width": "80px",
        "height": "80px",
        "margin-right": "15px"
    }, text: {
        display: "block"
    }, actionContainer: {
        top: "50%",
        right: "16px",
        position: "absolute",
        transform: "translateY(-50%)"
    }


}));

export const ItemWithIcon = ({iconSrc, primaryText, secondaryText, action, ...rest}) => {
    const classes = useStyles();
    return <Box className={classes.root} {...rest}>
        <Avatar variant="square" className={classes.avatar} alt="Remy Sharp" src={iconSrc}/>
        <div>
            <Typography data-test="primary-text" component="span" color="textPrimary" className={classes.text}>{primaryText}</Typography>
            <Typography component="p" color="textSecondary" className={classes.text}>{secondaryText}</Typography>
        </div>

        <div className={classes.actionContainer}>
            {action}
        </div>
    </Box>
}
