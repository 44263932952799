import { CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { Container } from "reactstrap";
import styled from "styled-components";
import { Row } from "reactstrap";

export const DashboardWidgetCard = styled(Card)`
    margin: 1em 0;
    min-height: 18em;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #ffffff;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }

    .MuiCardHeader-root {
        font-size: 0.55rem; // decreased font-size
        font-weight: 400;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e0e0e0;
        padding: 4px 10px; // decreased padding
    }

    .MuiTypography-h5 {
        font-size: 1.1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.334;
        letter-spacing: 0em;
      }

    .MuiCardContent-root:last-child {
        padding-bottom: 16px;
    }
`;


export const DashboardTitle = styled.h2`
    margin-bottom: 1em;
`;

export const DashboardContainer = styled(Container)`
    @media (max-width: 768px) {
        padding: 1em;
    }
`;

export const FlexCardContent = styled(CardContent)`
    flex: 1;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`;



export const DashboardRow = styled(Row)`
    display: flex;

    > div { // Targets direct children divs, which would be the cols in this case.
        display: flex;
        flex-direction: column;
    }

    > div > div { // Targets the direct child div of each col which would be your widgets/cards.
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;