import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Container, FormHelperText, Link, makeStyles, TextField, Typography} from '@material-ui/core';
import Page from "../common/Page";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {StringParam, useQueryParam} from "use-query-params";
import {toaster} from "evergreen-ui";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3)
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ResetPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const [code] = useQueryParam('code', StringParam);

    return (
        <Page className={classes.root} title="Register">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Paper className={classes.paper}>
                        <Formik
                            initialValues={{
                                code: code,
                                password: '',
                                passwordConfirmation: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    password: Yup.string().max(255).required('password is required'),
                                    passwordConfirmation: Yup.string()
                                        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                                })
                            }
                            onSubmit={onSubmit}
                        >
                            {(all) => <Content {...all}/>}
                        </Formik>
                    </Paper>
                </Container>
            </Box>
        </Page>
    );

    function Content(props) {
        if (!props.status) return Form(props);

        if (props.status.responseStatus === 200) {
            return <ResultSuccess/>
        }

        if (props.status.responseStatus === 400) {
            return <ResultNotFound/>
        }

        return Form(props);


    }

    function ResultNotFound() {
        return <div>
            <Alert severity="error">
                <AlertTitle>Reset email expired</AlertTitle>
                The email you used to reset your password was expired or no longer valid.
                Try to reset it again.
            </Alert>
            <Box my={2}>
                <Button
                    color="primary"
                    onClick={() => history.push("/forgotten-password")}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Reset your password
                </Button>
            </Box>
        </div>;
    }

    function ResultSuccess() {
        return <div>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                You've successfully updated your password
            </Alert>
            <Box my={2}>
                <Button
                    color="primary"
                    onClick={() => history.push("/login")}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Login now
                </Button>
            </Box>
        </div>;
    }

    function Form({
                      handleSubmit, touched, errors, handleBlur,
                      handleChange, values, isSubmitting, status
                  }) {
        return <form onSubmit={handleSubmit}>
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h4"
                >
                    Reset password
                </Typography>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    Insert your new password and you're good to go!
                </Typography>
            </Box>

            <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
            />
            <TextField
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                fullWidth
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                label="Password Confirmation"
                margin="normal"
                name="passwordConfirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.passwordConfirmation}
                variant="outlined"
            />
            {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>
                    {errors.policy}
                </FormHelperText>
            )}
            <Box my={2}>
                <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    Send
                </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
                Remembered it already?{' '}
                <Link component={RouterLink} to="/login">Log in</Link>
            </Typography>
        </form>;
    }

    function onSubmit(values, actions) {
        fetch("/api/auth/reset-password", {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(values)
        }).then(value => {
            if (value.status === 200 || value.status === 400) {
                actions.setStatus({responseStatus: value.status});
            } else {
                toaster.danger("Password reset failed", {
                    description: value.status + " " + value.statusText,
                    duration: 10
                })
            }
        }).finally(() => {
            actions.setSubmitting(false);
        });

    }
};

export default ResetPassword;
