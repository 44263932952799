import React, {Component} from 'react';
import './index.css'
import 'react-tabs/style/react-tabs.css';
import FileUploaderWithProgress from "./FileUploadWithProgress";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import CopyPasteSpecs from "./CopyPasteSpecs";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import ListAltIcon from '@material-ui/icons/ListAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => ({
    pageTitle: {
        "margin-bottom": theme.spacing(2),
    }, tabsSection: {
        "margin-top": theme.spacing(3),

    }, tabsPanelSection: {
        "margin-top": theme.spacing(1)
    }
});

class AddRecommendations extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {value: 0};
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {

        const workspaces = this.props.workspaces;
        if (!workspaces.fetched) return <p>Fetching workspaces...</p>;

        let cur = workspaces.current;

        const {classes} = this.props;


        return (
            <div>
                <Box className={classes.pageTitle}>
                    <Typography variant="h5" component="h5">
                        Add Recommendations to <b>{cur.name}</b>
                    </Typography>
                </Box>
                <Divider/>
                <Paper className={classes.tabsSection}>
                    <Tabs value={this.state.value} variant="fullWidth"
                          onChange={this.handleChange}>
                        <Tab icon={<ListAltIcon/>} label="Copy/Paste from Excel"/>
                        <Tab icon={<CloudUploadIcon/>} label="Excel File upload"/>
                    </Tabs>
                </Paper>

                <Paper className={classes.tabsPanelSection}>
                    <TabPanel value={this.state.value} index={0}>
                        <CopyPasteSpecs workspaces={workspaces}/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <FileUploaderWithProgress/>
                    </TabPanel>
                </Paper>
            </div>


        )

    }
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}


function mapsStateToProps(state) {
    return {"workspaces": state.workspaces};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(withStyles(styles)(AddRecommendations)));
