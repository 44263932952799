import React from "react";

import { Table } from "reactstrap";
import styled from "styled-components";

import CardHeader from "@material-ui/core/CardHeader";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { generateUrlForWorkspace } from "../Workspace/common";
import { DashboardWidgetCard, FlexCardContent } from "./DashboardComponents";
import "./style.css";


const NotImplemented = styled(NavLink)`
  color: #bd0404;
  font-weight: bolder;
  font-size: x-large;  
`;

const Recommended = styled(NavLink)`
  color: #187d06;
  font-weight: bolder;
  font-size: x-large;  
`;



const WidgetTable = styled(Table)`
      margin-bottom: -1rem;
      
      thead {
        margin-bottom: 10em;
      }
      
      thead th, thead td {
        padding: 0.75em 0 0.75em 0;
      }
      
      tbody th, tbody td {
        padding:  0;
      }
      
      tbody th {
        vertical-align: middle;
      }
      
      th:nth-child(1) {  
         width: 7.5em;
      }
      
      thead th {
        font-size: small;
        border-top: none!important;
        text-align: center;
      }
      
      td {
        text-align: center;
      }
      
      
`;

class RecommendationTracker extends React.Component {


  notImplementedLinkForTag = (tag) => {
    return generateUrlForWorkspace(this.getWorkspaceNumber()) + "?diffs=" + tag;
  };

  recommendedLinkForTag = (tag) => {
    return generateUrlForWorkspace(this.getWorkspaceNumber()) + "?recc=" + tag;
  };

  getWorkspaceNumber() {
    return this.props.match.params.workspaceNumber;
  }

  render() {

    if (!this.props.workspaceStats.fetched) return null;

    const stats = this.props.workspaceStats.recommendationStats;

    stats.wrongTitle = stats.recommendedTitleCount - stats.implementedTitleCount;
    stats.wrongH1 = stats.recommendedH1sCount - stats.implementedH1sCount;
    stats.wrongMeta = stats.recommendedMetaDescriptionsCount - stats.implementedMetaDescriptionsCount;
    stats.wrongRedirect = stats.recommendedDestinationUriCount - stats.implementedDestinationUriCount;

    return (
      <DashboardWidgetCard>
        <CardHeader title="Recommendation Tracker" />
        <FlexCardContent>

          <WidgetTable>
            <thead>
              <tr>
                <th></th>
                <th><NavLink to={this.notImplementedLinkForTag("Title,H1,Meta,Redirect")}>Not implemented</NavLink></th>
                <th><NavLink to={this.recommendedLinkForTag("Title,H1,Meta,Redirect")}>Recommended</NavLink></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Title</th>
                <td><NotImplemented to={this.notImplementedLinkForTag("Title")}>{stats.wrongTitle}</NotImplemented></td>
                <td><Recommended to={this.recommendedLinkForTag("Title")}>{stats.recommendedTitleCount}</Recommended></td>
              </tr>
              <tr>
                <th scope="row">MetaDescr</th>
                <td><NotImplemented to={this.notImplementedLinkForTag("Meta")}>{stats.wrongMeta}</NotImplemented></td>
                <td><Recommended to={this.recommendedLinkForTag("Meta")}>{stats.recommendedMetaDescriptionsCount}</Recommended></td>
              </tr>
              <tr>
                <th scope="row">H1</th>
                <td><NotImplemented to={this.notImplementedLinkForTag("H1")}>{stats.wrongH1}</NotImplemented></td>
                <td><Recommended to={this.recommendedLinkForTag("H1")}>{stats.recommendedH1sCount}</Recommended></td>
              </tr>
              <tr>
                <th scope="row">Redirect</th>
                <td><NotImplemented to={this.notImplementedLinkForTag("Redirect")}>{stats.wrongRedirect}</NotImplemented></td>
                <td><Recommended to={this.recommendedLinkForTag("Redirect")}>{stats.recommendedDestinationUriCount}</Recommended></td>
              </tr>
            </tbody>
          </WidgetTable>
        </FlexCardContent>

      </DashboardWidgetCard>

    );
  }
}


function mapsStateToProps(state) {
  return { ...state.stats };
}


export default withRouter(connect(mapsStateToProps)(RecommendationTracker));
