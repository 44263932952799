import { Box, Button, Checkbox, FormHelperText, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import { StringParam, useQueryParam } from "use-query-params";
import * as Yup from 'yup';
import Page from "../common/Page";
import UserSelectedPlan from "./UserSelectedPlan";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3)
    },
    formContainer: {
        maxWidth: theme.breakpoints.values.sm
    },
    mainContainer: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const SignUpView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [priceId] = useQueryParam('priceId', StringParam);



    return (
        <Page className={classes.root} title="Register">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Grid container className={classes.mainContainer} spacing={3}>
                    <Grid item className={classes.formContainer}>
                        <Paper className={classes.paper}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    passwordConfirmation: '',
                                    policy: false
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                        password: Yup.string().min(8).max(255).required('password is required'),
                                        passwordConfirmation: Yup.string()
                                            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                                        policy: Yup.boolean().oneOf([true], 'This field must be checked')
                                    })
                                }
                                onSubmit={onSubmit}
                            >
                                {(all) => <FormContent {...all}/>}
                            </Formik>
                        </Paper>
                    </Grid>
                    <UserSelectedPlan/>
                </Grid>
            </Box>
        </Page>
    );

    function FormContent(props) {
        if (props.status && props.status.isAccountCreationSuccess) {
            return SubmitSuccessful()
        }

        return Form(props);
    }

    function SubmitSuccessful() {

        return <Box mb={3}>
            <Alert severity="success" data-test="signup-result">
                <AlertTitle>Success</AlertTitle>Account created! — <strong>check it out!</strong>
            </Alert>
            <Box my={2}>
                <Button
                    data-test="login-action"
                    color="primary"
                    onClick={() => history.push("/login" + renderPriceIdParam())}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Login now
                </Button>
            </Box>
        </Box>;
    }

    function SignupFailure() {
        return <Alert severity="error">There seems to be an account already associated with that email. <Link
            href={"/#/forgotten-password"}>Reset your password?</Link></Alert>;
    }

    function Form({
                      handleSubmit, touched, errors, handleBlur,
                      handleChange, values, isSubmitting, status
                  }) {
        return <form onSubmit={handleSubmit}>
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h4"
                >
                    Create new account
                </Typography>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    Use your email to create new account
                </Typography>
            </Box>
            <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"/>
            <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
            />
            <TextField
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                fullWidth
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                label="Password Confirmation"
                margin="normal"
                name="passwordConfirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.passwordConfirmation}
                variant="outlined"
            />
            <Box alignItems="center" display="flex" ml={-1}>
                <Checkbox checked={values.policy} name="policy" onChange={handleChange}/>
                <Typography color="textSecondary" variant="body1">
                    I have read the
                    {' '}
                    <Link color="primary"
                          component={RouterLink}
                          to="/#/terms-and-conditions"
                          underline="always"
                    >
                        Terms and Conditions
                    </Link>
                </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>
                    {errors.policy}
                </FormHelperText>
            )}
            <Box my={2}>
                <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    Sign up now
                </Button>
            </Box>
            {status && status.isError && <SignupFailure/>}
            <Typography color="textSecondary" variant="body1">
                Have an account?{' '}
                <Link component={RouterLink} to={"/login" + renderPriceIdParam()}>Log in</Link>
            </Typography>
        </form>;
    }

    function onSubmit(values, actions) {
        fetch("/api/auth/sign-up", {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({tcAccepted: values.policy, ...values})
        }).then(value => {
            if (value.status !== 201) {
                throw new Error();
            }
            actions.setStatus({isAccountCreationSuccess: true});
        }).catch(error => {
            actions.setStatus({isError: true, error: error});
        }).finally(() => {
            actions.setSubmitting(false);
        });

    }

    function renderPriceIdParam() {
        if (!priceId) return "";
        return "?priceId=" + priceId;
    }

};

export default SignUpView;
