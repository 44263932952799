import React from "react"
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import SomethingWentWrong from "./components/ErrorComponents/500";
import {RESET_STATE} from "./reducers";


// function logErrorToServer(error, info) {
//
//     apiFetch("/api/log-client-error", {
//         method: 'POST',
//         body: JSON.stringify({error: serializeError(error), info: info}),
//         headers: {"Content-Type": "application/json"}
//     }).then(response => {
//         if (response.status === 200) {
//             response.text().then(responseText => {
//                 toaster.info("Your error was submitted", {
//                     description: 'Your error was recorded in our system. For reference please use this ID ' + responseText,
//                     duration: 20
//                 })
//             });
//         }
//     });
// }

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: false}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.props.location.pathname !== prevProps.location.pathname) {
            //When the user navigate away, we can reset the error and let the
            //rest of the site work.
            this.setState({error: false});
        }


    }

    componentDidCatch(error, info) {
        // logErrorToServer(error, info);
        this.setState({error: true});
        //Clearing the state that caused the error hoping that is
        //limited to a specific url
        this.props.dispatch({type: RESET_STATE});
    }

    render() {
        if (this.state.error) {
            return <SomethingWentWrong/>;
        }

        return this.props.children;
    }
}

function mapsStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(ErrorBoundary));
