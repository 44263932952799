import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import fetchLoginStatus from "../login-status";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import LoginView from "../../Auth/LoginView";

function AuthenticatedRoute({component: Component, ...rest}) {
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);

    useEffect(() => {
        if (!login.userDetails.fetchAttempted) {
            dispatch(fetchLoginStatus(login));
        }
    }, [login.userDetails.fetchAttempted, dispatch, login])


    return (
        <Route
            {...rest}
            render={renderRoute}
        />
    )

    function renderRoute(props) {
        switch (getLoginStatus()) {
            case "UNKNOWN":
                return <InProgress/>;
            case "AUTHENTICATED":
                return <Component {...props} />;
            default:
                return <LoginView/>
        }
    }

    function getLoginStatus() {
        if (!login.userDetails.fetchAttempted) return "UNKNOWN";
        return login.userDetails.isLoggedIn ? "AUTHENTICATED" : "UNAUTHENTICATED";
    }
}

function InProgress() {
    return <div>
        <Box my={3} display="flex" height="100%" justifyContent="center">
            <Typography color="textPrimary" variant="h5">Loading...</Typography>
        </Box>
        <Box my={3} display="flex" height="100%" justifyContent="center">
            <CircularProgress/>
        </Box>
    </div>
}

export default AuthenticatedRoute;