import React from "react";
import {Alert, Button, Col, Form, FormGroup, Input, Label, Table} from "reactstrap";
import apiFetch from "../util/fetch-utils";
import {toaster} from "evergreen-ui";
import ExampleUsage from "./ExampleUsage";
import connect from "react-redux/es/connect/connect";
import {RECOMMENDATION_POST_ENDED, RECOMMENDATION_POST_STARTED} from "../../reducers/addRecommendations";
import LoadingOverlay from "react-loading-overlay";

class CopyPasteSpecs extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            uris: ""
        }
    }


    handleChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };

    addMultipleUri = (event) => {
        event.preventDefault();
        this.props.dispatch({type: RECOMMENDATION_POST_STARTED});

        let specs = this.state.uris.split('\n')
            .map(line => line.split('\t'))
            .filter(parts => parts[0].length > 0) //sometimes there's an extra line carriage at the end. parts[0] is the uri
            .map((parts, index) => {
                return {
                    rowIndex: index,
                    uri: parts[0],
                    destinationUri: parts[1],
                    title: parts[2],
                    metaDescription: parts[3],
                    h1: parts[4]
                }
            });

        apiFetch('/api/monitored-uris/add-recommendations?workspaceNumber=' + this.props.workspaces.current.seqNumber, {
            body: JSON.stringify(specs),
            method: 'POST',
            headers: {"Content-Type": "application/json"}
        }).then(value => {

            this.props.dispatch({type: RECOMMENDATION_POST_ENDED});

            if (value.status === 200) {
                toaster.success("Recommendations submitted successfully!");
                this.setState({uris: ""})
            }

            if (value.status >= 400) {
                toaster.danger("Unable to submit recommendations.", {
                    description: value.status + " " + value.statusText,
                    duration: 20
                })
            }

        });


    };


    render() {

        let uris = this.state.uris;
        let urisLines = uris.split('\n');

        //This should be visible only if the user has already inserted something
        //otherwise he might think he need to add an index
        const lineNumberHeader = this.state.uris.length > 0 ? (<th width="5%">#</th>) : null;

        return (
            <div>
                <Form>
                    <LoadingOverlay active={this.props.addRecommendations.isPostInProgress}
                                    spinner={true}
                                    text={"Adding recommendations..."}>
                        <ExampleUsage workspaceName={this.props.workspaces.current.name}/>
                        <Label for="uri">Paste from excel</Label>


                        <FormGroup row>
                            <Col sm={10}>
                                <Input type="textarea" onChange={this.handleChange} value={this.state.uris}
                                       name="uris" id="uris"/>
                            </Col>
                            <Col sm={1}>
                                <Button onClick={this.addMultipleUri} color={"info"}>Add</Button>
                            </Col>
                        </FormGroup>
                        <p/>
                    </LoadingOverlay>

             

                    {uris.length > 0 && urisLines.map((line, index) => {

                        var recomm = lineToRecommendation(line);

                        if (line.length > 0) {
                            if (!isValidUrl(recomm.srcUrl)) return (
                                <Alert key={index} color="danger">Invalid URL on
                                    line {index + 1}: <b>{recomm.srcUrl}</b></Alert>
                            );
                            if (invalidDstUrl(recomm.dstUrl)) return (
                                <Alert key={index} color="danger">Invalid Destination URL on
                                    line {index + 1}: <b>{recomm.dstUrl}</b></Alert>
                            );
                        }
                        return null;
                    })}

                    <h4> Recommendations Preview</h4>

                    {uris.length > 0 && 
                     <Alert color="info">
                     You're about to add <b>{urisLines.length-1}</b> recommendations
                     </Alert> 
                    }
    
                    

                    <Table bordered size={"sm"} responsive>
                        <thead>
                        <tr>

                            {lineNumberHeader}
                            <th>URL</th>
                            <th>Dest. URL</th>
                            <th>Title</th>
                            <th>Meta Descr</th>
                            <th>H1</th>
                        </tr>
                        </thead>
                        <tbody>

                        {uris.length > 0 && urisLines.map((line, index) => {

                            var recomm = lineToRecommendation(line);

                            if (line.length > 0) {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{recomm.srcUrl}</td>
                                        <td>{recomm.dstUrl}</td>
                                        <td>{recomm.title}</td>
                                        <td>{recomm.metaDescr}</td>
                                        <td>{recomm.H1}</td>
                                    </tr>
                                );
                            }

                            return null;

                        })}

                        </tbody>
                    </Table>
                </Form>
            </div>
        );
    }


}




function lineToRecommendation(line){

    return {
        "srcUrl":line.split('\t')[0],
        "dstUrl":line.split('\t')[1],
        "title":line.split('\t')[2],
        "metaDescr":line.split('\t')[3],
        "H1":line.split('\t')[4]   
    }

}



const invalidDstUrl = (url) => {
    if (!url) return false;
    const dstUrl = url.trim();
    return dstUrl && dstUrl !== "" && dstUrl !== "<REMOVE-RECOMMENDATION>" && !isValidUrl(dstUrl);
};


const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

function mapsStateToProps(state) {
    return {"addRecommendations": state.addRecommendations};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default connect(mapsStateToProps, mapDispatchToProps)(CopyPasteSpecs);
