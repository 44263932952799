import { USER_DETAILS_FETCHED } from "../../reducers/login";
import { ensureStatusOk } from "./fetch-utils";

let isFetching = false;

export default function fetchLoginStatus(login) {

    return dispatch => {
        if (!isFetching && !login.userDetails.fetchAttempted) {
            isFetching = true;
            return fetch("/api/user-details", {
                method: 'GET'
            })
                .then(ensureStatusOk)
                .then(response => {
                    if (response.status === 200) {
                        response
                            .json()
                            .then(responseJson => {
                                dispatch({
                                    type: USER_DETAILS_FETCHED,
                                    payload: {isLoggedIn: true, fetchAttempted: true, ...responseJson}
                                });
                            });
                    }
                }).catch(reason => {
                dispatch({
                    type: USER_DETAILS_FETCHED, payload: {isLoggedIn: false, fetchAttempted: true}
                });
            }).finally(() => {
                isFetching = false;
            })
        }

    }


}