import React from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {SettingsSection} from "../common/SettingsSection";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from "@material-ui/core/Avatar";
import {useDispatch, useSelector} from "react-redux";
import logo from "./analytics-logo.png"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import apiFetch from "../util/fetch-utils";
import {toaster} from "evergreen-ui";
import confirm from "../util/components/ConfirmDialog";
import Button from "@material-ui/core/Button";
import {GOOGLE_ANALYTICS_TOKEN_REMOVED} from "../../reducers/login";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }, inlineBox: {
        display: "flex",
        alignItems: "center"
    }, accountAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: "1rem"
    }, listRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }, addConnectionButton: {
        maxHeight: "2.5rem"
    }, analyticsButtonIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }, newConnectionNameInput: {
        width: "20rem"
    }
}));


export const AnalyticsDetails = () => {
    const analyticsTokens = useSelector(state => {
        return state.login.userDetails.analyticsTokens;
    });
    const classes = useStyles();
    const workspaces = useSelector(state => state.workspaces.data);
    const dispatch = useDispatch();

    return <Grid item xs={12} md={8}>
        <SettingsSection title={"Google Analytics Connected Accounts"}
                         cardActions={<AddConnectionAction/>}>
            <List component="nav" className={classes.root}>
                {analyticsTokens.map((token, i) => renderToken(i, token))}
            </List>
        </SettingsSection>
    </Grid>;


    function renderToken(i, token, actionHidden = false) {

        const action = (actionHidden) => {
            if (!actionHidden) {
                return <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => onDeleteToken(token)}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            }
            return null;
        }
        return <ListItem button key={i}>
            <ListItemAvatar>
                <Avatar variant="square" src={logo}/>
            </ListItemAvatar>
            <ListItemText primary={token.analyticsAccountOwner} secondary="GA Web Account"/>
            {action(actionHidden)}
        </ListItem>

    }

    function AddConnectionAction() {
        return <Button className={classes.addConnectionButton} size="small" color="primary" variant="contained"
                       startIcon={<Avatar variant="square" className={classes.analyticsButtonIcon} src={logo}/>}
                       onClick={onNewConnection}>Add Analytics account</Button>

        function onNewConnection() {
            window.location.href = ("/api/google-analytics/authorize");
        }
    }


    function onDeleteToken(token) {

        let workspaceList = workspaces
            .filter(workspace => workspace.analyticsSettings &&
                workspace.analyticsSettings.tokenId === token.id)
            .map((ws, i) => <li key={i}>
                <Link href={"#/ws/" + ws.seqNumber}>
                    {ws.name}
                </Link></li>)

        const WorkspaceListComponent = () => {
            if (workspaceList.length === 0) {
                return <p>This account is not currently used in any workspace.</p>;
            }
            return <div>
                <p>This account is currently used on the following workspaces:</p>
                <ul>
                    {workspaceList}
                </ul>
            </div>
        };

        confirm(
            {
                title: "Remove Google Analytics Connection",
                message: (
                    <div>
                        <p>Are you sure you want remove the following Analytics connection from this account?</p>
                        <List>{renderToken(0, token, true)}</List>
                        <p>All the workspaces using this account will no longer pull User traffic
                            (daily sessions) from Google Analytics.</p>
                        <WorkspaceListComponent/>
                        Past data will still be available.
                    </div>
                ),
                confirmText: "Ok",
                confirmColor: "primary",
                cancelColor: "link text-danger"
            })
            .then(val => {
                if (val) {
                    deleteToken(token);
                }
            });
    }

    function deleteToken(token) {
        apiFetch('/api/account-details/google-analytics-tokens/' + token.id, {
            method: 'DELETE',
            headers: {"Content-Type": "application/json"}
        }).then(value => {

            if (value.status === 200) {
                toaster.success("Analytics connection removed");
                dispatch({type: GOOGLE_ANALYTICS_TOKEN_REMOVED, id: token.id});
            }

            if (value.status !== 200) {
                toaster.danger("Delete failed", {
                    description: value.status + " " + value.statusText,
                    duration: 10
                })
            }

        });
    }
};