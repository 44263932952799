import * as d3 from "d3";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const baseDay = new Date();
baseDay.setDate(baseDay.getDate());

export function getDateAtIndex(days) {
    let date = new Date(baseDay.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}

export function getIndexForDate(dateStr) {

    const date = new Date(dateStr);

    const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const utc2 = Date.UTC(baseDay.getFullYear(), baseDay.getMonth(), baseDay.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


export function formatDateOnly(date) {
    return new Date(date).toISOString().split("T")[0];
}


export function dayOf(date) {

    return new Date(formatDateOnly(date));
}

export function formatDate(date) {
    return d3.timeFormat('%d %b %Y')(Date.parse(date));
}


export function isSameDay(d1, d2) {
    // console.log("Comparing", d1, d2);
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}


export function extractDestinationUri(currentValue) {
    if (redirectChainIsValid(currentValue)) {
        return currentValue.redirectChainElements[currentValue.redirectChainElements.length - 1].destinationURI;
    }
    return "";
}


export function extractRedirectChain(currentValue) {
    if (redirectChainIsValid(currentValue)) {
        return currentValue.redirectChainElements.map(it => {
            return it.httpStatus;
        }).join(", ");
    }
    return null;
}

function redirectChainIsValid(currentValue) {
    return currentValue && isValid(currentValue.redirectChainElements);
}

function isValid(val) {
    return Array.isArray(val) && val.length > 0;
}
