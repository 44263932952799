export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const HIDE_LOGIN_FAILED_ALERT = 'HIDE_LOGIN_FAILED_ALERT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GOOGLE_ANALYTICS_TOKEN_REMOVED = 'GOOGLE_ANALYTICS_TOKEN_REMOVED';
export const USER_DETAILS_FETCHED = 'USER_DETAILS_FETCHED';

const initialState = {
    userDetails: {
        username: "",
        fetchAttempted: false,
        isLoggedIn: undefined,
        analyticsTokens: [],
        billingInformation: {
            subscription: {
                plan: {}
            }
        }
    },
    loginFailedAlert: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case HIDE_LOGIN_FAILED_ALERT:
            return Object.assign({}, state, {loginFailedAlert: false});
        case LOGIN_FAILED:
            return Object.assign({}, state, {loginFailedAlert: true});
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {userDetails: action.payload});
        case LOGOUT_SUCCESS:
            return Object.assign({}, initialState);
        case GOOGLE_ANALYTICS_TOKEN_REMOVED:
            let analyticsTokens = state.userDetails.analyticsTokens.filter(token => token.id !== action.id);
            return Object.assign({}, state, {
                userDetails: {
                    analyticsTokens: analyticsTokens
                }
            });
        case USER_DETAILS_FETCHED:
            return Object.assign({}, state, {userDetails: action.payload});
        default:
            return state
    }
}


