import React from "react";
import {Col, FormGroup, Input, Table} from "reactstrap";
import {connect} from "react-redux";
import "./style.css";
import apiFetch from "../util/fetch-utils";
import {CURRENT_MONITORED_SELECT_SNAPSHOT, CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED} from "../../reducers/currentMonitoredUri";
import {MdLink} from "react-icons/md";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import {formatDate} from "./commons";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const MAX_LINKS_PER_PAGE = 10;
const searchPlaceholder = "search url";

class InboundLinks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInboundLinks: this.props.inboundLinks.curUrlRegex || ""
        }
    }


    handleChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };

    handleKeyPress = (target) => {
        if (target.charCode === 13) {
            this.onApply(target);
        }
    };

    onApply = (e) => {
        e.preventDefault();
        this.fetchInboundLinks(this.props.uri);
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isVisible && this.props.uri && this.props.uri !== this.props.inboundLinks.curUri) {
            this.fetchInboundLinks(this.props.uri)
        }
    }

    fetchInboundLinks(uri) {

        let filterQueryString = "";
        if (this.state.searchInboundLinks) filterQueryString = "&urlRegex=.*" + this.state.searchInboundLinks + ".*";

        if (!this.props.websiteCrawlId) {
            return;
        }

        apiFetch('/archive-api/page-links/inbound?uri=' + encodeURIComponent(uri) + "&websiteCrawlId=" + this.props.websiteCrawlId + filterQueryString)
            .then(response => response.json())
            .then(responseJson => {
                this.props.dispatch({
                    type: CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED,
                    payload: {
                        inboundLinks: {
                            ...responseJson,
                            curUri: this.props.uri,
                            curUrlRegex: this.state.searchInboundLinks
                        },
                    }
                });
            });
    }

    render() {
        let inboundLinks = this.props.inboundLinks;
        let isLastSnapshotSelected = this.props.curSnapshotDate && this.props.curSnapshotDate === this.props.lastSnapshotDate;

        if (!isLastSnapshotSelected) return <NotLastSnapshotFallback {...this.props}
                                                                     selectLastCrawl={this.selectLastCrawl}/>;

        return (
            <Container>
                <FormGroup row>
                    <Col sm={10}>
                        <Input name="searchInboundLinks" id="searchInboundLinks" placeholder={searchPlaceholder}
                               value={this.state.searchInboundLinks}
                               onChange={this.handleChange}
                               onKeyPress={this.handleKeyPress}/>
                    </Col>

                    <Button color="primary" className={"align-right-in-row"}
                            onClick={this.onApply}>Search</Button>

                </FormGroup>
                <Typography variant="body2" color="textSecondary" component="p">
                    Showing <b>{Math.min(MAX_LINKS_PER_PAGE, inboundLinks.content.length)}</b> of <b>{inboundLinks.totalElements}</b> Inbound links. Use '{searchPlaceholder}' to refine.
                </Typography>
                <br/>
                <InboundLinksTable inboundLinks={inboundLinks}/>
            </Container>
        )
    }


    selectLastCrawl = () => {
        this.props.dispatch({type: CURRENT_MONITORED_SELECT_SNAPSHOT, curSnapshotDate: this.props.lastSnapshotDate})
    }
}

const InboundLinksTable = ({inboundLinks}) => {
    return <Table size="sm" borderless striped hover>
        <thead>
        <tr>
            <th style={{width: "6em"}}>Source</th>
            <th>Url</th>
        </tr>
        </thead>
        <tbody>
        {inboundLinks.content.map((row, index) => {
            return (
                <tr key={index}>
                    <td>AHREF</td>
                    <td>{row} &nbsp;<a href={row} target="_blank" rel="noopener noreferrer"><MdLink/></a></td>
                </tr>
            );
        })}
        </tbody>
    </Table>;
};

const NotLastSnapshotFallback = (props) => {

    const additionalInfo = !Number.isInteger(props.inboundLinksCount) ?
        <span>. Unfortunately we don't have record of how many inbound links this page had </span> :
        <span> but we know that this page had <b>{props.inboundLinksCount}</b> inbound links </span>;

    return (
        <Container>
            <Card>
                <CardHeader title="Inbound links list is only available for the last crawl"/>

                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        We don't store the inbound links for previous crawls{additionalInfo} when we crawled it, on the <b>{formatDate(props.curSnapshotDate)}</b>
                    </Typography>
                </CardContent>

                <CardActions>
                    <Button onClick={props.selectLastCrawl} size="small" color="primary">
                        Go to last crawl
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};


const Container = styled.div`
 padding: 3em 1em 1em;
`;

function mapsStateToProps(state) {
    function getCrawlId() {
        const selectedValue = state.currentMonitoredUri.pageSnapshotsMap[state.currentMonitoredUri.lastSnapshotDate];
        if (!selectedValue || !selectedValue.lastCrawl) return undefined;
        return selectedValue.lastCrawl.websiteCrawlId;
    }

    return {
        websiteCrawlId: getCrawlId(),
        inboundLinks: state.currentMonitoredUri.inboundLinks,
        lastSnapshotDate: state.currentMonitoredUri.lastSnapshotDate,
        curSnapshotDate: state.currentMonitoredUri.curSnapshotDate
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapsStateToProps, mapDispatchToProps)(InboundLinks);
