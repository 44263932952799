import { toaster } from "evergreen-ui";
import {
    FETCH_CRAWL_STATUS_REQUEST,
    FETCH_CRAWL_STATUS_SUCCESS,
    FETCH_CRAWL_STATUS_FAILURE,
    START_CRAWL_SUCCESS,
    START_CRAWL_FAILURE,
    STOP_CRAWL_SUCCESS,
    STOP_CRAWL_FAILURE,
    CRAWL_STARTING
} from "../actions/crawls";

const initialState = {
    crawls: {},
    error: null,
};

const defaultCrawlStatus = {
    status: { pendingPages: 1, visitedPages: 0, errorMessage: null },
    startedAt: null,
    completedAt: null,
    trigger: { type: "USER_INITIATED_WORKSPACE" },
    owner: "... loading ...",
};

export const crawls = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CRAWL_STATUS_REQUEST:
            return { ...state, error: null };
        case FETCH_CRAWL_STATUS_SUCCESS:
            return {
                ...state,
                crawls: {
                    ...state.crawls,
                    [action.payload.crawlId]: {
                        ...defaultCrawlStatus,
                        ...action.payload.data,
                    },
                },
                error: null,
            };
        case FETCH_CRAWL_STATUS_FAILURE:
            return { ...state, error: action.error };

        case START_CRAWL_SUCCESS:
            return {
                ...state,
                crawls: {
                    ...state.crawls,
                    [action.payload.crawlId]: {
                        ...defaultCrawlStatus,
                        id: action.payload.id
                    },
                },
                error: null,
            };
        case START_CRAWL_FAILURE:
            toaster.danger(`Unable to start crawl: ${action.error}`);
            return state;
        default:
            return state;
    }
};
