import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { SettingsSection } from "../../common/SettingsSection";
import startIcon from './static/start_white.svg'
import startUgly from './static/start.svg'
import diamond from './static/diamond.svg'
import idea from './static/idea.svg'
import giftIcon from './static/giftbox.svg'
import { ItemWithIcon } from "../../common/ItemWithIcon";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { StringParam, useQueryParam } from "use-query-params";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import fetchLoginStatus from "../../util/login-status";
import { getProductFrom } from "./subscriptionUtils";


const useStyles = makeStyles((theme) => ({
    niceLink: {
        "text-decoration": "none",
        "&:hover": {
            color: "white",
            "text-decoration": "none",
        },
        "& *:hover": {
            color: "white",
            "text-decoration": "none",
        }
    }
}));

export const SubscriptionView = (props) => {
    const login = useSelector((state) => state.login);
    const [changeResult, setChangeResult] = useQueryParam('changeResult', StringParam);
    const [userPlanRefreshed, setUserPlanRefreshed] = useState(false);
    const billingInformation = useSelector((state) => state.login.userDetails.billingInformation);
    const classes = useStyles();
    const dispatch = useDispatch();

    const product = getProductFrom(billingInformation);

    function getPlanIcon(product) {
        if (!product || !product.featuresGroup) {
            return planIcons.generic;
        }
        return planIcons[product.featuresGroup];
    }


    return <Grid item xs={12}>
        <SettingsSection title={"Manage your subscription plan"}>
            <ChangePlanResult />
            <ItemWithIcon
                data-test="user-plan-container"
                action={<Button className={classes.niceLink} color="primary" variant="contained"
                    href="/#/change-subscription">Change plan</Button>}
                iconSrc={getPlanIcon(product)}
                primaryText={product.name}
                secondaryText={(product.price / 100).toFixed(2) + " / " +
                    product.priceInterval} />
        </SettingsSection>
    </Grid>


    function ChangePlanResult() {
        if (!changeResult) return null;

        if (changeResult === "success") {

            if (!userPlanRefreshed) {
                setTimeout(() => {
                    setUserPlanRefreshed(true);
                    login.userDetails.fetchAttempted = false
                    dispatch(fetchLoginStatus(login))
                }, 3000)
            }

            return (
                <Box mb={3}>
                    <Alert severity="success" onClose={() => {
                        setChangeResult(null)
                    }}>
                        <AlertTitle>Congratulations!</AlertTitle>
                        <Typography>
                            You've successfully changed your subscription. <span role="img" aria-label="Party popper, celebration">🎉🎉🎉</span>
                        </Typography>
                        <Typography color="textSecondary">It might take a few seconds to change below...</Typography>
                    </Alert>
                </Box>
            );
        }

        if (changeResult === "cancelled") {
            return (
                <Box mb={3}>
                    <Alert severity="info" onClose={() => {
                        setChangeResult(null)
                    }}>
                        <AlertTitle>Cancelled!</AlertTitle>
                        No changes were made.
                    </Alert>
                </Box>
            );
        }
        return null;
    }


};


const planIcons = {
    "generic": startUgly,
    "free": giftIcon,
    "starter": startIcon,
    "pro": diamond,
    "diamond": diamond,
    "premium": diamond,
    "business": diamond,
    "idea": idea
}