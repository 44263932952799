import React from 'react';
import { 
    List,
    ListItem, 
    ListItemText,
    Grid 
} from '@material-ui/core';

function CrawlDetails({ crawlStatus }) {
    const getFormattedDate = (dateString) => {
        if (!dateString) return "N/A";
        return new Date(dateString).toLocaleString(); 
    };

    const getStartedBy = (trigger, owner) => {
        return trigger.type === "USER_INITIATED_WORKSPACE" ? owner : "Scheduled";
    };

    const total = crawlStatus ? crawlStatus.status.visitedPages + crawlStatus.status.pendingPages : 0;

    return (
        <List>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ListItem>
                        <ListItemText primary="Started At" secondary={getFormattedDate(crawlStatus.startedAt)} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ListItem>
                        <ListItemText primary="Completed At" secondary={getFormattedDate(crawlStatus.completedAt)} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ListItem>
                        <ListItemText primary="Started By" secondary={getStartedBy(crawlStatus.trigger, crawlStatus.owner)} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ListItem>
                        <ListItemText primary="Completed / Total" secondary={`${crawlStatus.status.visitedPages} / ${total}`} />
                    </ListItem>
                </Grid>
                {crawlStatus.status.errorMessage && (
                    <Grid item xs={12}>
                        <ListItem>
                            <ListItemText primary="Error" secondary={crawlStatus.status.errorMessage} />
                        </ListItem>
                    </Grid>
                )}
            </Grid>
        </List>
    );
}

export default CrawlDetails;
