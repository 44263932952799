import apiFetch from "../../util/fetch-utils";
import {
    MONITORED_URI_REFERENCE_VALUE_UPDATED
} from "../../../actions/monitoredUri";

export const FIELD_EDIT_BEGIN = 'FIELD_EDIT_BEGIN';
export const FIELD_EDIT_HIDE = 'FIELD_EDIT_HIDE';
export const FIELD_EDIT_ERROR = 'FIELD_EDIT_ERROR';


export function fieldEditBegin(monitoredUriId, uri, fieldId, isArrayField, currentValue, recommendation) {
    return dispatch => dispatch({
        type: FIELD_EDIT_BEGIN,
        payload: { id: monitoredUriId, uri, fieldId, fieldName: fieldId, isArrayField, currentValue, recommendation }
    })

}

export function fieldEditHide() {
    return dispatch => dispatch({
        type: FIELD_EDIT_HIDE
    })
}

export function fieldEditCommit(monitoredUriId, fieldId, currentValue, recommendation) {

    //console.log("id" + fieldId)

    const newValue = {
        "currentValue": { [fieldId]: currentValue },
        "recommendation": { [fieldId]: recommendation },
    };

    return (dispatch) => {
        apiFetch('/api/monitored-uris/' + monitoredUriId, {
            body: JSON.stringify(newValue),
            method: 'PATCH',
            headers: { "Content-Type": "application/json" }
        }).then(response => {
            if (response.status === 204) {
                dispatch({
                    type: MONITORED_URI_REFERENCE_VALUE_UPDATED,
                    newValue: newValue.recommendation,
                    monitoredUriId: monitoredUriId
                });
                dispatch({ type: FIELD_EDIT_HIDE })
            } else {
                dispatch({ type: FIELD_EDIT_ERROR, payload: response });
            }
        });
    };


}

