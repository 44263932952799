import React from 'react';
import { Button, Typography, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const StartFirstCrawl = ({ onStart, crawlStarting }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h6">No crawl information available.</Typography>
            <Typography variant="body1">Start your first crawl to see the status here.</Typography>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.button}
                onClick={onStart}
                disabled={crawlStarting}
            >
                {crawlStarting ? <CircularProgress size={24} /> : "Start First Crawl"}
            </Button>
        </div>
    );
};

export default StartFirstCrawl;
