import Container from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import apiFetch, { dumpErrorOnCustomer, ensureStatusOk } from "../util/fetch-utils";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { StringParam, useQueryParam } from "use-query-params";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import fetchLoginStatus from "../util/login-status";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserSelectedPlan from "../Auth/UserSelectedPlan";
import { usePriceAmount } from "../AccountDetails/Subscription/subscriptionUtils";
import { BILLING_API_BASE_PATH, useStripePromise } from "./stripeClient";


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(1, 0, 6),
    }
}));

const Checkout = () => {
    const [sessionId, setSessionId] = useState("");
    const [redirect, setRedirect] = useState("");
    const login = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.login.userDetails.isLoggedIn);
    const classes = useStyles();
    const [priceId] = useQueryParam('priceId', StringParam);
    const priceAmount = usePriceAmount(priceId);
    const FREE_PRODUCT_SESSION_ID = "free-product";

    const stripePromise = useStripePromise();

    useEffect(() => {
        if (!login.userDetails.fetchAttempted) {
            dispatch(fetchLoginStatus(login));
        }
        if (isLoggedIn && priceAmount !== undefined) {
            if (priceAmount > 0) {
                createCheckoutSession(priceId);
            } else {
                setSessionId(FREE_PRODUCT_SESSION_ID);
            }
        }
    }, [priceId, login, priceAmount, dispatch, isLoggedIn]);


    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (login.userDetails.fetchAttempted && !isLoggedIn) {
        setRedirect("/register?priceId=" + priceId);
    }


    return (
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Content />
        </Container>
    );

    function Content() {
        if (!sessionId) return <CreatingPlan />
        return <PlanCreated />
    }

    function CreatingPlan() {
        return <>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                We're creating your new plan!
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
                We're provisioning the resources to activate your plan, hang on...
            </Typography>
            <Box display="flex" justifyContent="center" pt={3}>
                <CircularProgress />
            </Box>
        </>;
    }

    function PlanCreated() {
        return <>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Your plan is ready! <span role="img" aria-label="Rocket">🚀</span>
            </Typography>

            <Box display="flex" justifyContent="center" pt={3}>
                <UserSelectedPlan standalone />
            </Box>
            <Box display="flex" justifyContent="center" py={4}>
                <Button fullWidth variant="contained" color="primary" onClick={proceed} data-test="confirm-plan-button">
                    {(priceAmount > 0) ? "Proceed to payment" : "Confirm free plan"}
                </Button>
            </Box>
        </>
    }

    function proceed() {
        if ((priceAmount > 0)) {
            stripePromise.then(stripe => {
                return stripe.redirectToCheckout({ sessionId: sessionId });
            })
        } else {
            apiFetch(BILLING_API_BASE_PATH + "/subscribe-to-free", {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: priceId
            })
                .then(ensureStatusOk)
                .then(value => value.text())
                .then(value => {
                    setRedirect("/account/subscription/?changeResult=success");
                })
                .catch(dumpErrorOnCustomer);
        }
    }

    function createCheckoutSession(priceId) {
        apiFetch(BILLING_API_BASE_PATH + "/create-checkout-session", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: priceId
        })
            .then(ensureStatusOk)
            .then(value => value.text())
            .then(sessionId => {
                setSessionId(sessionId);
            })
            .catch(error => {
                console.log("Error", error)
            });
    }
}


export default Checkout;