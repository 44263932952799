import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ProductCard from "../Pricing/ProductCard";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, StringParam } from "use-query-params";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fetchPlans } from "../../actions/products";
import { usePrice, useProduct } from "../AccountDetails/Subscription/subscriptionUtils";

const useStyles = makeStyles((theme) => ({
    dealContainer: {
        width: theme.breakpoints.values.sm / 1.9,
        paddingBottom: theme.spacing(1)
    }
}));

export default function UserSelectedPlan({ standalone = false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const plans = useSelector(state => state.plans);
    const [priceId] = useQueryParam('priceId', StringParam);
    const product = useProduct(priceId);
    const price = usePrice(priceId);

    useEffect(() => {
        if (!plans.fetched) {
            dispatch(fetchPlans());
        }
    }, [dispatch, plans.fetched]);

    if (!priceId) return null;

    if (product) {
        return <Grid item className={classes.dealContainer + " dealContainer"}>
            {!standalone &&
                <Typography style={{ marginBottom: "1rem" }} variant="h4" align={"center"}>Your plan</Typography>}
            <ProductCard product={product}
                priceInterval={price.recurring.interval}
                standalone
                action={getAction(price)}
            />
        </Grid>;
    }

    return null;

    function getAction() {
        if (standalone) return <div></div>;

        if (price.unitAmountDecimal > 0) {
            return <Box px={"16px"} pb={"1rem"}>
                <Typography color={"textSecondary"} align={"center"}>
                    You will be redirected to payment options as soon as you're logged in <span role="img" aria-label="Champagne bottle">🍾</span>
                </Typography>
            </Box>
        }

        return <Box px={"16px"} pb={"1rem"}>
            <Typography color={"textSecondary"} align={"center"}>
                Your plan will be set as soon as you're logged in <span role="img" aria-label="Gift box">🎁</span>
            </Typography>
        </Box>
    }
}