import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    statusText: {
        fontSize: '1rem',
        whiteSpace: 'nowrap',
    },
    statusDynamic: {
        marginLeft: theme.spacing(1),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    statusContainer: {
        marginRight: theme.spacing(2),
    },
}));

function Status({ isCrawling, visitedPages }) {
    const classes = useStyles();
    const statusText = isCrawling ? (visitedPages === 0 ? "Pending..." : "In Progress") : "Done";

    return (
        <Box display="flex" alignItems="center" className={classes.statusContainer}>
            <Typography variant="body1" className={classes.statusText}>
                Crawl Status: <span className={classes.statusDynamic}>{statusText}</span>
            </Typography>
        </Box>
    );
}

export default Status;
