
import Confirm from "./Confirm";


/*
* Credits: https://antoniogazquez.me/
* Source: https://github.com/algm/reactstrap-confirm
* */

export default function confirm(options = {}) {
    const instance = new Confirm(options);

    return instance.open();
}
