import React from "react";

export const CountryFlag = (props) => {
  let cc = props.countryCode;

  if (!cc || cc.length < 2) return null;
  let normalCountryCode = normalizeCountryCode(props.countryCode);

  const style = { fontSize: 32 }

  return (<span style={style}>{getFlacCharacter(normalCountryCode)}</span>);

  function normalizeCountryCode(countryCode) {
    if (countryCode === "UK") return "GB";
    if (countryCode === "EN") return "GB";
    return countryCode;
  }

  function getFlacCharacter(countryCode) {
    // Source: https://github.com/thekelvinliu/country-code-emoji/blob/master/src/index.js
    const offset = 127397
    if (typeof countryCode !== "string") throw new TypeError("argument must be a string")
    const cc = countryCode.toUpperCase()
    return /^[A-Z]{2}$/.test(cc) ? String.fromCodePoint(...[...cc].map(c => c.charCodeAt() + offset)) : "❓"
  }

};
