import React, {Component} from "react";


import TextareaAutosize from "react-autosize-textarea";

import {IoMdAddCircleOutline, IoMdRemoveCircleOutline} from "react-icons/io";
import "./style.css"
import {Button, Card, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";


export class EditableFieldSingle extends Component {

    addRecommendation = () => {
        this.props.onChange(this.props.currentValue || "")
    };

    removeRecommendation = () => {
        this.props.onChange(null);
    };

    onRecommendationChange = (value) => {
        this.props.onChange(value);
    };

    render() {
        return (
            <Card body>
                <Form className={"field-editor-form"}>
                    <FormGroup>
                        <CurrentValueHeader/>
                        <CurrentValueBody value={this.props.currentValue}/>
                    </FormGroup>
                    <FormGroup>
                        <RecommendationHeader add={this.addRecommendation} remove={this.removeRecommendation}/>
                        <RecommendationBody value={this.props.recommendation} onChange={this.onRecommendationChange}/>
                    </FormGroup>
                </Form>
            </Card>
        );
    }
}

function CurrentValueHeader() {
    return <Label for="currentValue">Current Value</Label>;
}

function CurrentValueBody(props) {
    return (<div className="rounded-list-text">{props.value}</div>);
}

function RecommendationHeader(props) {
    return <Row id="recommendationLabel">
        <Col sm={9}>
            <Label for="recommendation">Recommended value</Label>
        </Col>
        <Col sm={3}>
            <Button onClick={props.add} className={"recommended-button"}
                    color={"success"}
                    title="Add recommendation">
                <div className={"center-vertically"}>
                    <IoMdAddCircleOutline size={27}/>
                </div>
            </Button>
            <Button onClick={props.remove} className={"recommended-button"}
                    color={"danger"}
                    title="Remove recommendation">
                <div className={"center-vertically"}>
                    <IoMdRemoveCircleOutline size={27}/>
                </div>
            </Button>
        </Col>
    </Row>;
}

function RecommendationBody(props) {
    const recommendation = props.value;
    if (recommendation !== null) {
        return (<RecommendationEditableField value={recommendation} onChange={props.onChange}/>);
    } else {
        return (<Input disabled value="No recommendation provided"/>);
    }
}

class RecommendationEditableField extends Component {

    handleChange = ({target}) => {
        this.props.onChange(target.value)
    };

    render() {
        return (
            <TextareaAutosize onChange={this.handleChange}
                              value={this.props.value}
                              className={'form-control editor edit-text'}/>
        );
    }
}

