import React from 'react';
import {Container} from "@material-ui/core";
import {AnalyticsDetails} from "./AnalyticsDetails";
import {UserDetails} from "./UserDetails";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {SubscriptionView} from "./Subscription/SubscriptionView";
import {useParams} from "react-router";


const useStyles = makeStyles((theme) => ({
    tabs: {
        marginBottom: "20px"
    }
}));


function getSectionIndex(section) {
    if (section === "subscription") {
        return 1;
    }
    return 0;
}


const AccountDetails = () => {

    const {section} = useParams()
    const [value, setValue] = React.useState(getSectionIndex(section));
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Container>
            <Box className={classes.tabs}>
                <Paper square>
                    <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                        <Tab label="General" {...a11yProps(0)}/>
                        <Tab label="Subscription" {...a11yProps(1)} />
                    </Tabs>
                </Paper>
                <Divider/>
            </Box>
            <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    <UserDetails/>
                    <AnalyticsDetails/>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    <SubscriptionView/>
                </Grid>
            </TabPanel>
        </Container>
    );
};


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`}{...other}>
            {value === index && (<div>{children}</div>)}
        </div>
    );
}

export default AccountDetails;