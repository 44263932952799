import React, { useState, useEffect } from 'react';
import { IconButton, Box, CircularProgress, makeStyles } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopCircleOutlinedIcon from '@material-ui/icons/StopCircleOutlined';

const useStyles = makeStyles((theme) => ({
    controlPanel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
    },
    statusIcon: {
        fontSize: 30,
    },
    noPadding: {
        padding: 0, // Remove padding for the buttons
    },
}));

function Controls({ isCrawling, onCrawlStart, crawlStarting }) {
    const classes = useStyles();

    const handleStartClick = () => {
        onCrawlStart();
    };


    return (
        <Box className={classes.controlPanel}>
            <IconButton 
                onClick={handleStartClick}
                disabled={isCrawling || crawlStarting}
                className={classes.noPadding}
            >
                <PlayCircleOutlineIcon className={classes.statusIcon} color={isCrawling || crawlStarting ? "disabled" : "primary"} />
            </IconButton>
            {/* <IconButton 
                onClick={handleStopClick}
                disabled={!isCrawling || localLoading}
                className={classes.noPadding}
            >
                <StopCircleOutlinedIcon className={classes.statusIcon} color={!isCrawling || localLoading ? "disabled" : "secondary"} />
            </IconButton> */}
            {(crawlStarting) && <CircularProgress size={24} />}
        </Box>
    );
}

export default Controls;
