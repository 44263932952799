import React, {Component} from "react";
import {MdLink} from "react-icons/md";

class PageSnapshotUrl extends Component{
    render() {
        const url = this.props.url;

        return (
            <tr>
                <td style={{width: "15%"}}>
                    <strong>URL</strong>
                </td>
                <td style={{width: "80%"}}>
                    <span>{url} &nbsp;<a href={url} target="_blank" rel="noopener noreferrer" ><MdLink/></a></span>
                </td>
                <td style={{width: "3em"}}>
                </td>
            </tr>
        )
    }
}


export default PageSnapshotUrl;
