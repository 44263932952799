import React from "react";

import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import {WORKSPACE_SELECTED_EVENT} from "../../actions/workspaces";


class SelectionListener extends React.Component {


    workspaceSelected = () => {
        return this.props.match.params.workspaceNumber !== undefined &&
            this.props.workspaces.current.seqNumber !== this.props.match.params.workspaceNumber;
    }

    componentDidMount() {
        if (this.workspaceSelected()) {
            this.props.dispatch({type: WORKSPACE_SELECTED_EVENT, payload: getWorkspaceNumber(this.props)});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

         // console.log("componentDidUpdate:", this.props)


        if (this.props.match.params.workspaceNumber !== undefined &&
            this.props.workspaces.current.seqNumber !== getWorkspaceNumber(this.props)
        ) {
            this.props.dispatch({type: WORKSPACE_SELECTED_EVENT, payload: getWorkspaceNumber(this.props)});
        }
    }


    render() {
        return null;
    }

}


function getWorkspaceNumber(props) {
    return parseInt(props.match.params.workspaceNumber, 10);
}

function mapsStateToProps(state) {
    return {"login": state.login, "workspaces": state.workspaces};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(SelectionListener));



