import { Badge } from "reactstrap";
import React, { Component } from "react";
import deepEqual from "deep-equal";
import { fetchIssueTrend, fetchWorkspaceStats } from "../../actions/stats";
import { connect } from "react-redux";
import { fieldEditBegin } from "../RecommendationEditor/redux/actions";
import { withRouter } from "react-router";
import { generateUrlForWorkspace } from "./common";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import DeleteConfirmationDialog from "./DeleteMonitoredUriConfirmationDialog";
import apiFetch from "../util/fetch-utils";
import { MONITORED_URI_DELETED } from "../../actions/monitoredUri";
import { toaster } from "evergreen-ui";
import { getSubfield } from "../util/defaults";

class UriTable extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            toolbarActive: false,
            deleteDialog: {
                isOpen: false
            }
        }
    }

    toggleToolbar = () => {
        this.setState((prevstate) => {
            return { toolbarActive: !prevstate.toolbarActive }
        })
    };

    deleteConfirmationDialogCallback = (deleteConfirmation, entity) => {
        this.setDeleteConfirmationDialogOpen(false);

        if (deleteConfirmation) {
            const id = entity.id;

            apiFetch('/api/monitored-uris/' + id, {
                method: 'DELETE'
            }).then(value => {

                if (value.status === 204) {
                    toaster.success("Deleted: " + entity.uri, { duration: 3 });
                    this.props.dispatch({ type: MONITORED_URI_DELETED, id: id });
                    this.props.dispatch(fetchWorkspaceStats(this.props.match.params.workspaceNumber));
                }

                if (value.status !== 204) {
                    toaster.danger("Delete failed " + entity.uri, {
                        description: value.status + " " + value.statusText,
                        duration: 10
                    })
                }
            });
        }
    };

    setDeleteConfirmationDialogOpen = (b) => {
        this.setState({ deleteDialog: { isOpen: b } });
    };

    deleteUrl = (monitoredUri) => {
        this.setState({ deleteDialog: { isOpen: true, entity: monitoredUri } })
    };


    render() {

        const toolbarActive = this.state.toolbarActive;

        return (
            <div>
                <DeleteConfirmationDialog {...this.state.deleteDialog} onClose={this.deleteConfirmationDialogCallback} />
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>

                                <TableCell>URL</TableCell>
                                <TableCell>Title</TableCell>
                                <Hidden xsDown><TableCell>Meta Description</TableCell></Hidden>
                                <Hidden xsDown><TableCell>H1</TableCell></Hidden>
                                <TableCell padding="checkbox">
                                    <IconButton onClick={() => this.toggleToolbar()}>
                                        <MoreVertIcon color="primary" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {this.props.tableData
                                .map((row, index) => {
                                    return <MyTableRow row={row} index={index} key={index} toolbarActive={toolbarActive}
                                        workspaceNumber={this.getWorkspaceNumber()}
                                        fieldEditBegin={this.props.fieldEditBegin}
                                        deleteUrl={this.deleteUrl}
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }

    getWorkspaceNumber() {
        return this.props.match.params.workspaceNumber;
    }
}


class MyTableRow extends React.Component {

    onEdit = (fieldId, isArrayField) => (row) => {
        if (this.props.row.currentValue != null) {
            this.props.fieldEditBegin(
                row.id,
                row.uri, fieldId, isArrayField,
                row.currentValue[fieldId],
                row.recommendation[fieldId]
            );
        }
    };


    render() {


        let toolbar = null;
        const toolbarActive = this.props.toolbarActive;
        if (toolbarActive) {
            toolbar = <TableCell padding="checkbox">
                <Tooltip title="Delete" placement="left">
                    <IconButton onClick={() => this.props.deleteUrl(this.props.row)}>
                        <DeleteIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        }

        return (
            <TableRow>

                <TableCell colSpan={toolbarActive ? 4 : 1}>
                    <div className="uri-table-column">
                        <DiffBadge row={this.props.row} />
                        <StatusCodeBadge row={this.props.row} workspaceNumber={this.props.workspaceNumber} />
                        <UrlValue value={this.props.row} />
                    </div>
                </TableCell>
                <TableColumn field="currentValue.title" hidden={toolbarActive} onEdit={this.onEdit("title", false)} row={this.props.row} />
                <Hidden xsDown><TableColumn field="currentValue.metaDescriptions" hidden={toolbarActive} onEdit={this.onEdit("metaDescriptions", true)} row={this.props.row} /></Hidden>
                <Hidden xsDown><TableColumn field="currentValue.h1s" colSpan={toolbarActive ? 1 : 2} hidden={toolbarActive} onEdit={this.onEdit("h1s", true)} row={this.props.row} /></Hidden>
                {/*<Hidden xsDown><TableColumn field="lastCrawl.inboundLinksCount.internal.ahref" hidden={!toolbarActive} row={this.props.row}/></Hidden>*/}
                {toolbar}
            </TableRow>
        )
    }


}


class StatusCodeBadge extends React.Component {


    render() {

        let props = this.props;

        if (props.row.currentValue == null || this.invalidRedirectChain(props)) return null;


        const redirectChain = props.row.currentValue.redirectChainElements;
        const statusCode = this.extractStatusCode(redirectChain);

        // console.log(redirectChain, getLastStatusCode(redirectChain), extractStatusCode(redirectChain));

        let color = "secondary";

        if (statusCode === 301) {
            color = "info";
        } else if (statusCode < 400) {
            color = "warning";
        } else {
            color = "danger";
        }

        let link = "#" + generateUrlForWorkspace(this.props.workspaceNumber) + "?httpStatus=" + statusCode;

        if (this.hasRedirect(redirectChain)) {
            return (
                <Badge href={link} color={color} pill>{statusCode}</Badge>
            )
        }


        return null;

    }

    invalidRedirectChain() {
        const elements = this.props.row.currentValue.redirectChainElements;
        return !elements || elements.length === 0;
    }

    hasRedirect(redirectChain) {
        return this.extractStatusCode(redirectChain) !== 200;
    }

    extractStatusCode(redirectChain) {

        //if (redirectChain.length <= 0) console.log("ROW:", this.props.row);

        const lastStatusCode = this.getLastStatusCode(redirectChain);

        //4xx 5xx
        if (lastStatusCode >= 400) {
            return lastStatusCode
        }

        //3xx
        if (redirectChain.length > 1) {
            return redirectChain[redirectChain.length - 2].httpStatus;
        }

        //2xx
        return lastStatusCode;
    }

    getLastStatusCode(redirectChain) {
        // console.log("RC:", redirectChain)
        return redirectChain[redirectChain.length - 1].httpStatus;
    }

}


class TableColumn extends Component {
    render() {

        let entity = this.props.row;
        let columnName = this.props.field;


        if (this.props.hidden) return null;

        return (
            <TableCell colSpan={this.props.colSpan} className="uri-table-column" onClick={() => this.props.onEdit(this.props.row)}>
                <div className="uri-table-column">
                    {entity ? getSubfield(entity, columnName) : ""}
                </div>
            </TableCell>
        );
    }
}


class UrlValue extends React.Component {
    render() {
        return (
            <a href={'/#/monitored-uris/' + this.props.value.id}>{this.props.value.uri}</a>
        );
    }
}

class DiffBadge extends Component {

    render() {


        let badge = null;

        let row = this.props.row;

        row.recommendation = row.recommendation || {};

        if (row.currentValue !== null) {

            if (Array.isArray(row.currentValue.redirectChainElements) && row.currentValue.redirectChainElements.length > 0) {
                row.currentValue.destinationUri = row.currentValue.redirectChainElements[row.currentValue.redirectChainElements.length - 1].destinationURI;
            }

            let curValue = filterFields(row.currentValue, ["title", "h1s", "metaDescriptions", "destinationUri"]);
            let refValue = filterFields(row.recommendation, ["title", "h1s", "metaDescriptions", "destinationUri"]);

            refValue = allowNullRecommendation(refValue, curValue);

            if (!deepEqual(curValue, refValue)) {
                console.trace("Different!\n", curValue, refValue);
                badge = (
                    <span>
                        <a href={'/#/monitored-uris/' + row.id}>
                            <Badge color="info" id={this.componentID}>
                                Diffs
                            </Badge>
                        </a>
                    </span>

                );
            }
        }

        return badge;
    }


}

/**
 * Null reference value means don't care about it,
 * no need to signal differences
 * */
function allowNullRecommendation(recommendation, currentValue) {
    Object.entries(recommendation).forEach(
        ([key, value]) => {
            if (value === null || value === undefined) {
                recommendation[key] = currentValue[key];
            }
        }
    );

    return recommendation;
}

function filterFields(currentValue, allowed) {
    let copy = {};

    allowed.forEach(field => {
        copy[field] = currentValue[field];
    });

    return copy;
}

function mapsStateToProps(state) {
    return { ...state.monitoredUri };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fieldEditBegin:
            (monitoredUriId, uri, fieldId, isArrayField, currentValue, recommendation) =>
                dispatch(fieldEditBegin(monitoredUriId, uri, fieldId, isArrayField, currentValue, recommendation)),
        fetchIssueTrend: function () {
            dispatch(fetchIssueTrend());
        }
    };
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(UriTable));

