import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCrawlStatus } from '../../actions/crawls';

const CrawlStatusPolling = ({ lastCrawlId }) => {
  const dispatch = useDispatch();
  const crawlStatus = useSelector((state) => state.crawls.crawls[lastCrawlId]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (lastCrawlId) {
      if (!crawlStatus) {
        dispatch(fetchCrawlStatus(lastCrawlId)); // Initial fetch
      }

      intervalRef.current = setInterval(() => {
        dispatch(fetchCrawlStatus(lastCrawlId));
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [lastCrawlId, dispatch]);

  useEffect(() => {
    if (crawlStatus && crawlStatus.status && crawlStatus.status.pendingPages === 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [crawlStatus]);

  return null;
};

export default CrawlStatusPolling;
