import withStyles from "@material-ui/core/styles/withStyles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";


const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'inline-block',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        marginBottom: "2rem"
    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    discountChip: {
        marginLeft: "1rem"
    }
}));

export const BillingIntervalButtons = ({priceInterval, handlePriceIntervalChange}) => {
    const classes = useStyles();

    return <Box className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
            <StyledToggleButtonGroup
                value={priceInterval}
                exclusive
                onChange={handlePriceIntervalChange}
                aria-label="text alignment">
                <ToggleButton value="month" color="primary">
                    Billed Monthly
                </ToggleButton>
                <ToggleButton value="year" color="primary">
                    Billed Annually
                    <Chip className={classes.discountChip} label="Discount!" color="primary"/>
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Paper>
    </Box>;
}