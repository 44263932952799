import {Col, FormGroup, Label} from "reactstrap";
import GoogleAnalyticsSelect from "./google-analytics";
import React from "react";
import {FormSection} from "../FormSection";

export const GoogleAnalyticsSettings = (props) => {
    return <FormSection label={"Google Analytics Connection"}>
        <FormGroup row>
            <Label for="viewId" sm={3}>Analytics View</Label>
            <Col sm={8}>
                <GoogleAnalyticsSelect setAnalyticsView={props.setAnalyticsView}
                                       ws={props.currentWorkspace}/>
            </Col>

        </FormGroup>
    </FormSection>
};