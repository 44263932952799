import {Card} from "reactstrap";
import React from "react";
import styled from "styled-components";


const FormSectionCont = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const FormGroupLabel = styled.h5`
    color: #9c9c9c;
    font-size: 1.1rem;
    margin-top: 1rem;
`;


export const FormSection = (props) => {
    return <FormSectionCont>
        <FormGroupLabel>{props.label}</FormGroupLabel>
        <Card body>
            {props.children}
        </Card>
    </FormSectionCont>
};