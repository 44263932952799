import React, { Component } from "react"

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "./style.css"
import { EditableFieldList } from "./EditableFieldList";
import { fieldEditCommit, fieldEditHide } from "./redux/actions";
import connect from "react-redux/es/connect/connect";
import { EditableFieldSingle } from "./EditableFieldSingle";


class RecommendationEditor extends Component {

    constructor(props) {
        super(props);
        this.state = { recommendation: props.recommendation };
    }

    toggle = (event) => {
        event.preventDefault();
        this.props.hide();
    };


    onChange = (newVal) => {
        this.setState({ recommendation: newVal })
    };

    onApply = () => {
        this.props.commit(
            this.props.id,
            this.props.fieldId,
            this.props.currentValue,
            this.state.recommendation
        )
    };

    static getDerivedStateFromProps(props, state) {
        if (!props.isOpen) {
            return { recommendation: null, editorId: null };
        }
        if (state.editorId !== getEditorId(props)) {
            return { recommendation: props.recommendation, editorId: getEditorId(props) };
        }
        return state;
    }


    render() {
        const props = this.props;
        return (
            <Modal className={"large80"} isOpen={this.props.isOpen} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>
                    Editing recommendation for <b>{capitalizeFirstLetter(props.fieldName)}</b> on <a href={props.uri}>{props.uri}</a>
                </ModalHeader>
                <ModalBody>
                    <EditableField fieldName={props.fieldName}
                                   currentValue={props.currentValue}
                                   recommendation={this.state.recommendation}
                                   isArrayField={props.isArrayField}
                                   onChange={this.onChange}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onApply}>Apply</Button>
                    {' '}
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

function getEditorId(val) {
    return val.id + val.fieldId;
}

function EditableField(props) {
    if (props.isArrayField) {
        return (
            <EditableFieldList fieldName={props.fieldName}
                               currentValue={props.currentValue}
                               recommendation={props.recommendation}
                               onChange={props.onChange}/>
        );

    } else {
        return <EditableFieldSingle fieldName={props.fieldName}
                                    currentValue={props.currentValue}
                                    recommendation={props.recommendation}
                                    onChange={props.onChange}/>
    }
}


const multiple = {
    isOpen: true,
    id: "",
    uri: "",
    fieldName: "",
    currentValue: [],
    recommendation: null
};

// const single = {
//     isOpen: true,
//     id: "12345abc",
//     uri: "http://example.com",
//     fieldName: "h1s",
//     recommendation: null,
//     currentValue: "This is how it actually is!"
// };

RecommendationEditor.defaultProps = multiple;

function mapsStateToProps(state) {
    return { ...state.fieldEditor };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        hide: function () {
            dispatch(fieldEditHide());
        },
        commit: function (monitoredUriId, fieldId, currentValue, recommendation) {
            dispatch(fieldEditCommit(monitoredUriId, fieldId, currentValue, recommendation));
        }
    };
}


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export default connect(mapsStateToProps, mapDispatchToProps)(RecommendationEditor);
