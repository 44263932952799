import apiFetch, {dumpErrorOnCustomer, ensureStatusOk} from "../components/util/fetch-utils";
import {PLANS_FETCHED} from "../reducers/plans";

let isFetching = false;

export function fetchPlans() {
    return dispatch => {
        if (!isFetching) {
            isFetching = true;
            apiFetch("/api/subscription/plans")
                .then(ensureStatusOk)
                .then(value => value.json())
                .then(value => dispatch({type: PLANS_FETCHED, payload: value}))
                .catch(dumpErrorOnCustomer)
                .finally(() => {
                    isFetching = false;
                })
        }
    }
}