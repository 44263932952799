import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {AnalyticsAccount} from "./AnalyticsAccount";

export const AnalyticsViewDetails = ({settings, classes}) => {
    if (!settings.tokenId) return null;
    return (
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>
                        <AnalyticsAccount account={settings}/>
                    </TableCell>
                </TableRow>
                {renderProperty("View Name", settings.viewName)}
                {renderProperty("View ID", settings.viewId)}
            </TableBody>
        </Table>

    );

    function renderProperty(key, value) {
        return <TableRow key={key}>
            <TableCell size={"small"} className={classes.tableHead}>{key}</TableCell>
            <TableCell size={"small"}>{value}</TableCell>
        </TableRow>;
    }
};

