export const PLANS_FETCHED = "PLANS_FETCHED";

const initialState = {
    fetched: false,
    data: [
        {
            name: "",
            prices: [{
                recurring: {
                    interval: "month"
                }
            }],
            features: []
        }
    ]
};

export default (state = initialState, action) => {
    function getPriceForProduct(product) {
        return product.prices.find(price => price.recurring.interval === "month").unitAmountDecimal;
    }

    switch (action.type) {
        case PLANS_FETCHED:
            action.payload
                .sort((a, b) => getPriceForProduct(a) - getPriceForProduct(b));
            return Object.assign({}, state, {fetched: true, data: action.payload});
        default:
            return state
    }
}


