import apiFetch, {dumpErrorOnCustomer, ensureStatusOk} from "../util/fetch-utils";
import {loadStripe} from "@stripe/stripe-js/pure";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router";

export const BILLING_API_BASE_PATH = "/api/subscription";


export function useStripePromise() {
    const [cookies] = useCookies(['stub-stripe-checkout']);
    const history = useHistory();

    if (cookies["stub-stripe-checkout"]) return Promise.resolve({
        redirectToCheckout: (sessionId) => {
            history.push("/account/subscription?changeResult=success")
        }
    });

    return apiFetch(BILLING_API_BASE_PATH + "/stripe-public-key")
        .then(ensureStatusOk)
        .then(r => r.text())
        .then(key => loadStripe(key))
        .catch(dumpErrorOnCustomer);
}