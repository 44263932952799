import React, {useEffect, useState} from 'react';
import {Box, Button, Container, makeStyles, Typography} from '@material-ui/core';
import Page from "../common/Page";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {StringParam, useQueryParam} from "use-query-params";
import {toaster} from "evergreen-ui";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3)
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const EmailVerifyView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [code] = useQueryParam('code', StringParam);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (code) {
            fetch("/api/auth/email-verify?code=" + code, {
                method: 'GET',
            }).then(value => {
                if (value.status === 200 || value.status === 400) {
                    setStatus(value.status);
                } else {
                    toaster.danger("Email verification failed", {
                        description: value.status + " " + value.statusText,
                        duration: 10
                    })
                }
            }).catch(reason => {
                console.log("reason: ", reason)
            });
        }

    }, [code]);

    return (
        <Page className={classes.root} title="Register">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Paper className={classes.paper}>
                        <Content status={status}/>
                    </Paper>
                </Container>
            </Box>
        </Page>
    );

    function Content(props) {

        const verificationStatus = props.status;

        if (verificationStatus === 200) {
            return <ResultSuccess/>
        }

        if (verificationStatus === 400) {
            return <ResultNotFound/>
        }

        return <InProgress/>;
    }

    function InProgress() {
        return <div>
            <Box my={3} display="flex" height="100%" justifyContent="center">
                <Typography color="textPrimary" variant="h5">Email verification in progress</Typography>
            </Box>
            <Box my={3} display="flex" height="100%" justifyContent="center">
                <CircularProgress/>
            </Box>
        </div>
    }

    function ResultNotFound() {
        return <div>
            <Alert severity="warning">
                <AlertTitle>Email verification failure</AlertTitle>
                <p>The verification email you used was expired or no longer valid. </p>
                <p>Login into your account, go to account settings and request a new verification email.</p>
                <p> Alternatively you can contact us on info@myseotoolbox.com</p>
            </Alert>
            <Box my={2}>
                <Button
                    color="primary"
                    onClick={() => history.push("/login")}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Login now
                </Button>
            </Box>
        </div>;
    }

    function ResultSuccess() {
        return <div>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                You've successfully verified your email address.
            </Alert>
            <Box my={2}>
                <Button
                    color="primary"
                    onClick={() => history.push("/login")}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Login now
                </Button>
            </Box>
        </div>;
    }
};

export default EmailVerifyView;
