import React, { Component } from 'react';

import { Badge, Row, Table } from 'reactstrap';

import './style.css';
import 'bootstrap/dist/css/bootstrap.css'
import apiFetch from '../util/fetch-utils';
import UriTimeline from "./UriTimeline";
import CurrentMonitoredUriDetails from "./CurrentMonitoredUriDetails";
import connect from "react-redux/es/connect/connect";
import {
    COMPLETED,
    CURRENT_MONITORED_URI_CHANGED,
    CURRENT_MONITORED_URI_CHANGES_FETCHED,
    CURRENT_MONITORED_URI_FETCHED,
    CURRENT_MONITORED_URI_PERFORMANCES_FETCHED,
    CURRENT_MONITORED_URI_SNAPSHOT_FETCH_404,
    CURRENT_MONITORED_URI_SNAPSHOT_FETCH_STARTED,
    CURRENT_MONITORED_URI_SNAPSHOT_FETCH_SUCCESS
} from "../../reducers/currentMonitoredUri";
import Buttons from "./Buttons";
import { extractDestinationUri } from "./commons";
import 'react-tabs/style/react-tabs.css';
import InboundLinks from "./InboundLinks";
import PageSnapshotUrl from "./PageSnapshotUrl";
import { PageSnapshotField } from "./PageSnapshotField";
import * as d3 from "d3";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import { Paper } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import { toaster } from 'evergreen-ui';

export const SOURCE_PERF_TAG = "src";
export const DESTINATION_PERF_TAG = "dst";


// Inbound link count wasn't released before this date
// So if the value is empty it means 0 links after release and means "I don't know" before
const ILC_FEATURE_RELEASE_DATE = "2019-08-18";

function getInternalAhrefLinksCount(curSnapshotDate, lastCrawl) {

    if (!lastCrawl) return "-";

    const inboundLinksCount = lastCrawl.inboundLinksCount;

    const beforeILCFeatureRelease = Date.parse(curSnapshotDate) < Date.parse(ILC_FEATURE_RELEASE_DATE);

    if (!inboundLinksCount || !inboundLinksCount.internal) {
        if (beforeILCFeatureRelease) {
            return "-";
        } else {
            return 0;
        }
    }
    return inboundLinksCount.internal.ahref;
}

class CurrentMonitoredUri extends Component {


    defaults = {
        fetchStatus: COMPLETED,
        title: "",
        metaDescriptions: [],
        h1s: [],
        h2s: [],
        canonicals: [],
        destinationUri: ""
    };

    constructor(props) {
        super(props);

        let id = this.props.match ? this.props.match.params.id : this.props.id;


        this.default = {
            id: id,
            selectedTab: 0
        };

        this.state = this.default;
    };

    componentDidMount() {
        this.props.dispatch({ type: CURRENT_MONITORED_URI_CHANGED });


        apiFetch('/api/monitored-uris/' + this.state.id + '?projection=InlineCurValue')
            .then(response => {

                if (response.status === 200) {
                    response
                        .json()
                        .then(responseJson => {

                            const dstUri = extractDestinationUri(responseJson.currentValue);
                            if (responseJson.currentValue) {
                                responseJson.currentValue.destinationUri = dstUri;
                            }
                            this.props.dispatch({ type: CURRENT_MONITORED_URI_FETCHED, payload: responseJson });

                            this.fetchPerformances(responseJson);

                            const pageChangesUrl = '/archive-api/page-changes?uri=' + responseJson.uri;
                            apiFetch(pageChangesUrl)
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error('Unable to fetch page changes. ' + response.status + ' ' + response.statusText);
                                    }
                                    return response.json();
                                })
                                .then(crawls => {
                                    this.props.dispatch({
                                        type: CURRENT_MONITORED_URI_CHANGES_FETCHED,
                                        payload: crawls
                                    });
                                })
                                .catch(error => {
                                    console.error('There was a problem with the fetch operation:', error.message);
                                    toaster.danger(error.message);
                                });

                        });


                }
            });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        const nextPropsDate = this.props.curSnapshotDate;

        if (nextPropsDate) {

            const curSnapshot = this.props.pageSnapshotsMap[nextPropsDate];

            if (!curSnapshot || !curSnapshot.fetchStatus) {


                this.props.dispatch({
                    type: CURRENT_MONITORED_URI_SNAPSHOT_FETCH_STARTED,
                    curSnapshotDate: nextPropsDate
                });


                apiFetch('/archive-api/page?uri=' + this.props.uri + "&date=" + nextPropsDate)
                    .then(response => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(responseJson => {
                                    responseJson.destinationUri = extractDestinationUri(responseJson);
                                    this.props.dispatch(
                                        {
                                            type: CURRENT_MONITORED_URI_SNAPSHOT_FETCH_SUCCESS,
                                            curSnapshotDate: nextPropsDate,
                                            value: responseJson
                                        });
                                });
                        } else if (response.status === 404) {
                            this.props.dispatch(
                                {
                                    type: CURRENT_MONITORED_URI_SNAPSHOT_FETCH_404,
                                    curSnapshotDate: nextPropsDate
                                });
                        }
                    });


            }


        }


    }

    fetchPerformances(responseJson) {
        let srcPath = new URL(responseJson.uri).pathname;
        const ws = responseJson.workspaceNumber;

        this.fetchPerformanceFor(ws, srcPath, SOURCE_PERF_TAG);

        if (responseJson.currentValue && responseJson.currentValue.destinationUri)
            try {
                let dstPath = new URL(responseJson.currentValue.destinationUri).pathname;
                if (dstPath.length > 0 && dstPath !== srcPath) {
                    this.fetchPerformanceFor(ws, dstPath, DESTINATION_PERF_TAG);
                }
            } catch (e) {
                console.error("Unable to fetch:", e);
            }
    }

    fetchPerformanceFor(ws, path, tag) {

        apiFetch('/api/page-performances/search/findByPath' +
            '?workspaceNumber=' + ws + '&path=' + encodeURIComponent(path))
            .then(response => response.json())
            .then(responseJson => {
                this.props.dispatch({
                    type: CURRENT_MONITORED_URI_PERFORMANCES_FETCHED,
                    payload: {
                        tag: tag,
                        path: path,
                        data: responseJson
                    }
                });
            });
    }

    handleChange = (event, newValue) => {
        this.setState({ selectedTab: newValue })
    };

    render() {

        const currentValue = this.extractSnapshotValues().cur;
        const internalAhref = getInternalAhrefLinksCount(this.props.curSnapshotDate, currentValue.lastCrawl);

        let crawlDate = formatDate(currentValue.createDate, this.props.curSnapshotDate);
        let inLinksCountBadge = <Badge color="secondary">{internalAhref}</Badge>;
        return (
            <div>
                <Container>
                    <ChartCard>
                        <UriTimeline />
                        <Row>
                            <Buttons />
                        </Row>
                    </ChartCard>
                </Container>


                <Container>
                    <Paper square>
                        <Table borderless>
                            <tbody>
                                <PageSnapshotField diffDisabled fieldName="Crawl date" fieldValueCur={crawlDate} />
                                <PageSnapshotUrl url={this.props.uri} />
                            </tbody>
                        </Table>
                    </Paper>


                    <Paper square>
                        <Tabs
                            value={this.state.selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={this.handleChange}
                            component="div">

                            <Tab label="Details" />
                            <Tab label={<div>Inbound Links {inLinksCountBadge}</div>} />
                        </Tabs>
                    </Paper>
                    <Paper square>
                        <TabPanel value={this.state.selectedTab} index={0}>
                            <CurrentMonitoredUriDetails
                                values={this.extractSnapshotValues()} />
                        </TabPanel>
                        <TabPanel value={this.state.selectedTab} index={1}>
                            <InboundLinks uri={this.props.uri} isVisible={this.state.selectedTab === 1} inboundLinksCount={internalAhref} />
                        </TabPanel>
                        <TabPanel value={this.state.selectedTab} index={2}>
                            Item Three
                        </TabPanel>
                    </Paper>

                </Container>


            </div>
        );
    }

    extractSnapshotValues() {

        let curSnapshot = this.props.pageSnapshotsMap[this.props.curSnapshotDate];

        if (!curSnapshot || curSnapshot.fetchStatus !== COMPLETED) {
            return {
                cur: { ...this.defaults, ...curSnapshot },
                ref: this.defaults
            };
        }

        return {
            cur: curSnapshot,
            ref: this.props.recommendation
        }
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            <MinHeightBox>{children}</MinHeightBox>
        </Typography>
    );
}

const MinHeightBox = styled(Box)`
 min-height: 800px;
`;

const ChartCard = styled(Card)`
 margin-bottom: 2em;
 margin-top: 2em;
 padding: 1em;
`;


function formatDate(date, fallback) {
    if (date === undefined) {
        return d3.timeFormat('%d %b %Y')(Date.parse(fallback));
    }
    return d3.timeFormat('%d %b %Y - %H:%M:%S')(Date.parse(date));
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

function mapsStateToProps(state) {
    return { ...state.currentMonitoredUri };
}

export default connect(mapsStateToProps, mapDispatchToProps)(CurrentMonitoredUri);
