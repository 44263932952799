import React, {Component} from 'react';

import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import * as d3 from "d3";
import connect from "react-redux/es/connect/connect";
import {CURRENT_MONITORED_SELECT_SNAPSHOT} from "../../reducers/currentMonitoredUri";
import {formatDateOnly, getDateAtIndex, getIndexForDate, isSameDay} from "./commons"
import {DESTINATION_PERF_TAG, SOURCE_PERF_TAG} from "./index";


function formatter(date) {
    return d3.timeFormat('%d/%m')(date);
}

function range(num) {
    return [...Array(num).keys()]
}


class UriTimeline extends Component {


    render() {

        // console.log("orig:",this.props.data);
        // console.log("mapped:", changes);


        let divStyle = {
            width: "100%",
            height: "200px"
        };

        // console.log("Perf:", this.props.pagePerformances);

        let dateArray = range(this.props.numDaysHistory)
            .map(index => {
                const curDate = getDateAtIndex(index);
                const sessions = this.getSessionsForDate(curDate);
                return {
                    date: curDate.getTime(),
                    dateObj: curDate,
                    sessionsValueFound: true,
                    sessionsAtSrc: sessions.src,
                    sessionsAtDst: sessions.dst,
                    selected: this.props.curSnapshotIndex === index
                }
            });


        //Override current selected with monitoredUri.currentValue
        if (this.props.curSnapshotDate) {
            const indexForDate = getIndexForDate(this.props.curSnapshotDate);
            dateArray[indexForDate].selected = true;
        }


        this.applyPageChangesTo(dateArray);


        let dtsPerformanceLine = null;

        if (this.props.pagePerformances.length > 1) {

            dtsPerformanceLine = <Line type="monotone" dataKey='sessionsAtDst' stroke="#5C8BF2"
                                       dot={false}
                                       isAnimationActive={false}/>;
        }


        return (
            <div style={divStyle}>


                <ResponsiveContainer>
                    <LineChart data={dateArray} onClick={(event) => {
                        if (event !== null) {
                            //console.log("event clicked:", date);
                            const date = formatDateOnly(event.activePayload[0].payload.dateObj);

                            this.selectSnapshot(date)
                        }
                    }}>

                        <XAxis reversed={true} dataKey="date" tickFormatter={formatter}/>
                        <Tooltip content={<CustomTooltip/>}/>

                        {dtsPerformanceLine}


                        <Line type="monotone" dataKey='sessionsAtSrc' stroke='#FFBB28'
                              activeDot={{r: 8}}
                              dot={<CustomizedDot/>}
                              isAnimationActive={false}
                        />


                    </LineChart>
                </ResponsiveContainer>

            </div>


        );
    }


    applyPageChangesTo(dateArray) {
        this.props.pageChanges.forEach(pageChange => {
            const crawlDate = new Date(pageChange.crawlDate);
            const index = dateArray.findIndex(item => isSameDay(item.dateObj, crawlDate));
            if (index >= 0) {
                pageChange.changes.forEach(change => {

                    //Key represent the field where the change happened (title, h1s, etc...)
                    //eg: dateArray = [{date:xyz, title:true }] represent a title change in that date

                    dateArray[index][change.key] = true;
                });
            }
        });
    }

    selectSnapshot(date) {
        this.props.dispatch({type: CURRENT_MONITORED_SELECT_SNAPSHOT, curSnapshotDate: date})
    }

    getSessionsForDate(curDate) {

        let src = this.getSessionForTag(curDate, SOURCE_PERF_TAG);
        let dst = this.getSessionForTag(curDate, DESTINATION_PERF_TAG);

        return {
            src: src,
            dst: dst
        };
    }


    getSessionForTag(curDate, tag) {
        const sourcePerfs = this.props.pagePerformances.find(perfs => {
            return perfs.tag === tag;
        });

        // console.log("sourceperff",sourcePerfs)

        if (sourcePerfs) {
            let pagePerformances = sourcePerfs.data.find(curPagePerf => {
                return isSameDay(new Date(curPagePerf.date), curDate)
            });

            if (pagePerformances !== undefined) {
                return pagePerformances.sessions;
            }
        }
        return 0;
    }
}


class CustomTooltip extends React.Component {


    render() {
        const {active, payload, label} = this.props;

        if (active && payload !== null && payload.length > 0) {

            const changedSummary = payload[0].payload;

            return (
                <div className="custom-tooltip">
                    <p className="label">{formatter(label)}</p>

                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>

                        {payload.map((data, index) => {
                            return this.renderLineValue(index, data)
                        })}
                        {this.renderChanges(changedSummary)}

                    </ul>

                </div>
            );
        }

        return null;
    }

    renderLineValue(index, data) {


        function translate(dataKey) {
            switch (dataKey) {
                case "sessionsAtSrc":
                    return "Sessions (source URL)";
                case "sessionsAtDst":
                    return "Sessions (dest URL)";
                default:
                    return dataKey;

            }
        }

        return <li key={index} className="recharts-tooltip-item"
                   style={{
                       display: "block",
                       paddingTop: "4px",
                       paddingBottom: "4px",
                       color: data.stroke
                   }}>
            <span className="recharts-tooltip-item-name">{translate(data.dataKey)}</span>
            <span className="recharts-tooltip-item-separator">: </span>
            <span className="recharts-tooltip-item-value">{data.value > 0 ? data.value : "0"}</span>
        </li>;
    }

    renderChanges(changes) {


        const changesArray = Object.keys(changes)
            .filter(key => key !== "selected")
            .filter(key => key !== "sessionsValueFound")
            .filter(key => changes[key] === true)
            .map(key => this.translate(key));

        if (changesArray.length === 0) return null;

        return <li key="changes" className="recharts-tooltip-item"
                   style={{
                       display: "block",
                       paddingTop: "4px",
                       paddingBottom: "4px",
                       color: "black"
                   }}>
            <span className="recharts-tooltip-item-name">Changes</span>
            <span className="recharts-tooltip-item-separator">: </span>
            <span className="recharts-tooltip-item-value">{changesArray.join(", ")}</span>
        </li>;
    }

    translate(key) {
        switch (key) {
            case "title":
                return "Title";
            case "metaDescriptions":
                return "Meta Description";
            case "h1s":
                return "H1";
            case "redirectChainElements":
                return "Redirect chain";
            default:
                return key;

        }
    }
}

class CustomizedDot extends React.Component {
    render() {

        let {cx, cy, payload} = this.props;
        const chartHeight = this.props.height;
        if (cy === null) cy = chartHeight - 5;

        let size = 15;

        if (payload.selected) {
            return (
                <svg x={cx - (size / 2)} y={cy - (size / 2)} width={size} height={15} fill="green" viewBox="0 0 15 15">
                    <g>
                        <circle id="svg_2" cx="50%" cy="50%" r="7.5"/>
                    </g>
                </svg>
            );
        }

        if (payload.h1s || payload.title || payload.metaDescriptions || payload.redirectChainElements) {

            let fill = payload.redirectChainElements ? "green" : "blue";

            return (
                <svg x={cx - (size / 2)} y={cy - (size / 2)} width={size} height={15} fill={fill} viewBox="0 0 15 15">
                    <g>
                        <circle id="svg_2" cx="50%" cy="50%" r="7.5"/>
                        <text x="50%" y="50%"
                              textAnchor="middle" dy=".33em"
                              fontSize={size}
                              fill="#ffffff"
                              strokeWidth="0"
                              fontFamily="Helvetica, Arial, sans-serif"
                              stroke="#000"
                              fontWeight="bold">C
                        </text>
                    </g>

                </svg>
            );
        }


        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


function mapsStateToProps(state) {
    return {...state.currentMonitoredUri};
}

export default connect(mapsStateToProps, mapDispatchToProps)(UriTimeline);
