import { Box, Button, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import { StringParam, useQueryParam } from "use-query-params";
import * as Yup from 'yup';
import { LOGIN_FAILED, LOGIN_SUCCESS } from "../../reducers/login";
import Page from "../common/Page";
import { ensureStatusOk } from "../util/fetch-utils";
import UserSelectedPlan from "./UserSelectedPlan";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3)
    },
    formContainer: {
        marginTop: "3.5rem",
        maxWidth: theme.breakpoints.values.sm
    }, mainContainer: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [priceId] = useQueryParam('priceId', StringParam);

    const login = useSelector((state) => state.login);
    let loggedIn = login.userDetails.isLoggedIn;

    if (loggedIn) {
        return <Redirect to="/"/>
    }

    return (
        <Page className={classes.root} title="Login">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Grid container className={classes.mainContainer} spacing={3}>
                    <Grid item className={classes.formContainer}>
                        <Paper className={classes.paper}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email('Please type a valid email').max(255).required('Email is required'),
                                    password: Yup.string().max(255).required('Password is required')
                                })}
                                onSubmit={(values, actions) => {

                                    const data = new FormData();

                                    data.append("username", values.email);
                                    data.append("password", values.password);

                                    fetch("/api/login", {
                                        method: 'POST',
                                        body: data
                                    })
                                        .then(ensureStatusOk)
                                        .then(value => {
                                            dispatch({
                                                type: LOGIN_SUCCESS, payload: {
                                                    username: values.email,
                                                    isLoggedIn: true
                                                }
                                            });

                                            if (priceId) {
                                                history.push("/checkout?priceId=" + priceId)
                                            }

                                        })
                                        .catch(error => {
                                            actions.setStatus(error);
                                            dispatch({type: LOGIN_FAILED});
                                        }).finally(() => {
                                        actions.setSubmitting(false);
                                    });
                                }}
                            >
                                {({
                                      errors,
                                      status,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      touched,
                                      values
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box mb={3}>
                                            <Typography
                                                color="textPrimary"
                                                variant="h4"
                                            >
                                                Log in
                                            </Typography>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="body2"
                                            >
                                                Log in on your My SEO Toolbox account
                                            </Typography>
                                        </Box>

                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email Address"
                                            margin="normal"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(touched.password && errors.password)}
                                            fullWidth
                                            helperText={touched.password && errors.password}
                                            label="Password"
                                            margin="normal"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                        />
                                        <Link
                                            component={RouterLink}
                                            to="/forgotten-password"
                                        >
                                            Forgotten your password?
                                        </Link>
                                        <Box my={2}>
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                name="submit"
                                                variant="contained"
                                            >
                                                Log in now
                                            </Button>
                                        </Box>
                                        {status && <IncorrectPassword/>}
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Don&apos;t have an account?
                                            {' '}
                                            <Link
                                                component={RouterLink}
                                                to="/register"
                                            >
                                                Sign up
                                            </Link>
                                        </Typography>
                                    </form>
                                )}
                            </Formik>
                        </Paper>
                    </Grid>
                    <UserSelectedPlan/>
                </Grid>
            </Box>
        </Page>
    );


};


function IncorrectPassword() {
    return <Alert severity="error">Incorrect email or password. <Link href={"/#/forgotten-password"}>Forgotten your
        password?</Link></Alert>;
}

export default LoginView;
