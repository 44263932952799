import ReactGA from 'react-ga';
import {useHistory, useLocation} from "react-router";
import {useEffect} from "react";


const GoogleAnalyticsTag = () => {
    const history = useHistory();
    const location = useLocation();

    function init() {
        ReactGA.initialize('UA-176752131-1');
    }

    function reportToGA(location) {
        ReactGA.pageview(location.pathname + location.search);
    }

    useEffect(() => {
        init();
        reportToGA(location);
        history.listen((location) => {
            reportToGA(location);
        });
    }, [history, location])


    return null;
}

export default GoogleAnalyticsTag;