import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent, makeStyles} from "@material-ui/core";
import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/Info';
import Link from "@material-ui/core/Link";
import styled from "styled-components";


export const GrayBox = styled.div`
    border-color: #e0e0e0;
    outline: 0;
    padding: 1rem;
    justify-content: center;
    background-color: #f5f5f5;    
    margin: 1.5rem -24px;        
`;

const useStyles = makeStyles({
    link: {
        "margin-top": "1rem"
    },
});


export default function DeleteConfirmationDialog(props) {

    const {onClose, isOpen, entity} = props;

    const handleClose = () => {
        onClose(false);
    };

    const preventDefault = event => event.preventDefault();

    const classes = useStyles();

    if (!isOpen) return <div/>;


    return (

        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
            <DialogTitle id="simple-dialog-title">Do you want to remove this URL from this workspace?</DialogTitle>
            <DialogContent dividers>

                <Link className={classes.link} component={"div"} href="#" onClick={preventDefault}>
                    {entity.uri}
                </Link>


                <GrayBox component="span" m={1}>
                    <Grid container spacing={2}>

                        <Grid item>
                            <InfoIcon color="primary" fontSize="large"/>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Once deleted, the URL will not be present in this workspace and the associated recommendations
                                        (if present) will be permanently deleted as well.
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Don't worry, we won't delete the history (all the previous crawls), so if you want to add this URL back later,
                                        or if it's re-discovered by our crawlers, you will still see its full history.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </GrayBox>


            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false, entity)} color="primary">
                    Cancel
                </Button>
                <Button autoFocus onClick={() => onClose(true, entity)} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
