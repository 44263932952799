import Typography from "@material-ui/core/Typography";
import MUIButton from "@material-ui/core/Button";
import React from "react";

export const NoGAConnected = (classes, actionCallback) => () => {
    return (
        <div className={classes.noOptionsMessage}>
            <Typography>No Google Analytics accounts connected. </Typography>
            <MUIButton onClick={actionCallback}>Add</MUIButton>
        </div>
    );
};
export const NoViewsAvailable = (classes, views, setFilters) => () => {
    function getMessage() {
        if (views.length > 0) {
            return <Typography>No view matches the Website URL for this workspace.
                ({views.length} hidden)</Typography>;
        } else {
            return <Typography>No view available for current account</Typography>;
        }
    }

    function getButton() {
        if (views.length > 0) {
            return <MUIButton onClick={() => {
                setFilters({workspaceUrlActive: false})
            }}>Clear filters</MUIButton>;
        }
        return null;
    }

    return (
        <div className={classes.noOptionsMessage}>
            {getMessage()}
            {getButton()}
        </div>
    );
};