import {useDispatch, useSelector} from "react-redux";
import {fetchPlans} from "../../../actions/products";
import {useEffect} from "react";


export function getProductFrom(billingInformation) {
    if (!billingInformation || !billingInformation.subscription || !billingInformation.subscription.plan)
        return freePlan;
    return billingInformation.subscription.plan;
}

export function getPriceIdFrom(billingInformation) {

    return billingInformation.subscription.priceId;
}

export function usePrice(priceId) {
    const dispatch = useDispatch();
    const plans = useSelector(state => state.plans);

    useEffect(() => {
        if (!plans.fetched) {
            dispatch(fetchPlans());
        }
    }, [dispatch, plans]);

    if (!priceId) return undefined;
    return plans.data
        .flatMap(p => p.prices)
        .find(price => price.id === priceId);
}

export function usePriceAmount(priceId) {
    const price = usePrice(priceId);

    if (price) return price.unitAmountDecimal;
    return undefined;
}

export function useProduct(priceId) {
    const plans = useSelector(state => state.plans);
    const price = usePrice(priceId);

    if (!priceId) return undefined;

    if (price) {
        return plans.data.find(p => p.id === price.product);
    }

    return undefined;
}

const freePlan = {
    priceId: "FREE",
    name: "Free plan",
    featuresGroup: "free",
    price: 0,
    priceInterval: "month"
};