import apiFetch from "../components/util/fetch-utils";

export const FETCH_CRAWL_STATUS_REQUEST = 'FETCH_CRAWL_STATUS_REQUEST';
export const FETCH_CRAWL_STATUS_SUCCESS = 'FETCH_CRAWL_STATUS_SUCCESS';
export const FETCH_CRAWL_STATUS_FAILURE = 'FETCH_CRAWL_STATUS_FAILURE';
export const CRAWL_STARTING = 'CRAWL_STARTING';
export const START_CRAWL_SUCCESS = 'START_CRAWL_SUCCESS';
export const START_CRAWL_FAILURE = 'START_CRAWL_FAILURE';
export const STOP_CRAWL_SUCCESS = 'STOP_CRAWL_SUCCESS';
export const STOP_CRAWL_FAILURE = 'STOP_CRAWL_FAILURE';

export const fetchCrawlStatus = (crawlId) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CRAWL_STATUS_REQUEST, payload: crawlId });

        try {
            const response = await apiFetch(`/crawl-repository/crawls/${crawlId}`);
            if (response.status === 200) {
                const data = await response.json();
                dispatch({ type: FETCH_CRAWL_STATUS_SUCCESS, payload: { crawlId, data } });
            } else {
                throw new Error(`Fetch failed with status ${response.status}`);
            }
        } catch (error) {
            dispatch({ type: FETCH_CRAWL_STATUS_FAILURE, error: error.message });
        }
    };
};

export const startCrawl = (workspaceNumber) => {
    return async (dispatch) => {

        dispatch({ type: CRAWL_STARTING, payload: {workspaceNumber} });

        try {
            const response = await apiFetch('/api/crawl-workspace', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ workspaceNumber, numConnections: 1 })
            });

            if (response.status === 200) {
                const data = await response.json();
                dispatch({ type: START_CRAWL_SUCCESS, payload: { crawlId: data.crawlId } });
                return data;
            } else {
                throw new Error(`Start crawl failed with status ${response.status}`);
            }
        } catch (error) {
            dispatch({ type: START_CRAWL_FAILURE, error: error.message });
        }
    };
};

export const stopCrawl = (workspaceNumber) => {
    return async (dispatch) => {
        try {
            // Implement the stop crawl API call once the endpoint is available
            // For now, we will stub the success response
            dispatch({ type: STOP_CRAWL_SUCCESS });
        } catch (error) {
            dispatch({ type: STOP_CRAWL_FAILURE, error: error.message });
        }
    };
};
