import React from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import {generateUrlForWorkspace} from "../Workspace/common";


const DashboardLink = (props) => {


    const workspaces = props.workspaces;
    if (workspaces.current.seqNumber === undefined) return null;

    const dashboardUrl = "/#" + generateUrlForWorkspace(workspaces.current.seqNumber);

    return (
        <Nav className="ml-auto" navbar>
            <NavItem>
                <NavLink href={dashboardUrl}>Dashboard</NavLink>
            </NavItem>
        </Nav>

    )
};


export default DashboardLink;
