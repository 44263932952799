import apiFetch from "../components/util/fetch-utils";


export const FETCH_ISSUE_TREND_REQUEST = 'FETCH_ISSUE_TREND_REQUEST';
export const FETCH_ISSUE_TREND_SUCCESS = 'FETCH_ISSUE_TREND_SUCCESS';
export const FETCH_ISSUE_TREND_FAILURE = 'FETCH_ISSUE_TREND_FAILURE';


export const FETCH_WORKSPACE_STATS_IN_PROGRESS = 'FETCH_WORKSPACE_STATS_IN_PROGRESS';
export const FETCH_WORKSPACE_STATS_SUCCESS = 'FETCH_WORKSPACE_STATS_SUCCESS';
export const FETCH_WORKSPACE_STATS_FAILURE = 'FETCH_WORKSPACE_STATS_FAILURE';


export function fetchWorkspaceStats(workspaceNumber) {


    return dispatch => {

        dispatch({type: FETCH_WORKSPACE_STATS_IN_PROGRESS});

        apiFetch('/api/stats?workspaceNumber=' + workspaceNumber)
            .then(response => {
                if (response.status === 200) {
                    response.json().then(responseJson => {
                        dispatch({type: FETCH_WORKSPACE_STATS_SUCCESS, payload: responseJson});
                    });
                } else {
                    dispatch({type: FETCH_WORKSPACE_STATS_FAILURE, error: response});
                }
            });
    }

}


export function fetchIssueTrend() {


    return dispatch => {

        dispatch({type: FETCH_ISSUE_TREND_REQUEST});

        apiFetch('/api/stats/issue-trend')
            .then(response => {
                if (response.status === 200) {
                    response.json().then(responseJson => {
                        dispatch({type: FETCH_ISSUE_TREND_SUCCESS, payload: responseJson});
                    });
                } else {
                    dispatch({type: FETCH_ISSUE_TREND_FAILURE, error: response});
                }
            });
    }

}

