import {Card, CardHeader, makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiCardHeader-title": {
            fontSize: "16px",
        }, "& h6": {
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
        }
    }
}));


export const SettingsSection = (props) => {
    const classes = useStyles();

    return <Card className={classes.root}>
        <CardHeader title={props.title}/>
        <Divider/>
        <CardContent>
            <CardActions disableSpacing >
                {props.cardActions}
            </CardActions>
            {props.children}
        </CardContent>

    </Card>
};