export default function defaults(base, defaultValues) {

    let copy = Object.assign({}, base);

    Object.keys(defaultValues).forEach(k => {
        let val = getSubfield(base, k);
        if (val === null || val === undefined) {
            copy[k] = defaultValues[k];
        }
    });

    return copy;
}

export function getOptionalNested(obj, path, defaultVal) {
    if (!obj) return defaultVal;
    let val = getNextNestedObject(obj, path);
    return val ? val : defaultVal;
}

function getNextNestedObject(obj, path) {
    let pathTokens = path.split('.');
    const cur = obj[pathTokens[0]];
    if (cur && pathTokens.length > 1) {
        return getNextNestedObject(cur, pathTokens.splice(1).join("."))
    } else {
        return cur;
    }
}

export function createNestedObject(path, value) {
    let pathTokens = path.split('.');
    let fieldValue = value;
    if (pathTokens.length > 1) {
        fieldValue = createNestedObject(pathTokens.splice(1).join("."), value);
    }
    return {[pathTokens[0]]: fieldValue};
}


export function getSubfield(object, field) {
    let fieldTokens = field.split('.');

    for (const fieldToken of fieldTokens) {
        if (!fieldToken || !object[fieldToken]) return;
        if (fieldToken in object) {
            object = object[fieldToken];
        } else {
            return;
        }
    }

    return object;
}
