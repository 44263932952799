import React from "react";

import { Col, Row, Table } from "reactstrap";
import styled from "styled-components";

import CardHeader from "@material-ui/core/CardHeader";
import { Doughnut } from 'react-chartjs-2';
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { fetchWorkspaceStats } from "../../actions/stats";
import { generateUrlForWorkspace } from "../Workspace/common";
import { DashboardWidgetCard, FlexCardContent } from "./DashboardComponents";
import "./style.css";


const COLORS = ['#187d06', '#5c8bf2', '#eba100', '#fe000b', '#fe000b'];

const StatusCode200Link = styled(NavLink)`
  color: #187d06;
  font-weight: bolder;
`;

const StatusCode301Link = styled(NavLink)`
  color: #5c8bf2;
  font-weight: bolder;
`;


const ErrorLink = styled(NavLink)`
  color: #bd0404;
  font-weight: bolder; 
`;

const WidgetTable = styled(Table)`
    
      margin-bottom: 0;
      
      tbody  {
        vertical-align: middle;
        text-align: center;
      }
      
      thead th {
        font-size: small;
        text-align: center;
      }

      td  {
        text-align: center;
      }     
      
`;


const ConstHeightCol = styled(Col)`
    margin-top: auto;
    margin-bottom: auto;
`;


const HttpStatusHeader = styled.span`
  background-color: ${props => COLORS[props.index]};
  font-weight: bold;
  padding: .2em .5em;
  color: white;
`;





class IndexStatsWidget extends React.Component {


    linkForStatusCodeFilter = (code) => {
        return generateUrlForWorkspace(this.getWorkspaceNumber()) + "?httpStatus=" + code;
    };


    getWorkspaceNumber() {
        return this.props.match.params.workspaceNumber;
    }


    render() {

        let data = {
            labels: ["HTTP200", "HTTP301", "HTTP3xx", "HTTP4xx", "HTTP5xx"],
            datasets: [{
                data: [],
                backgroundColor: COLORS,
            }],
            legend: { display: false },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        };

        if (!this.props.workspaceStats.fetched) return null;

        let stats = this.props.workspaceStats.statusCodeStats;

        data.datasets[0].data[0] = stats.just200Count;
        data.datasets[0].data[1] = stats.http301Count;
        data.datasets[0].data[2] = stats.http3xxCount;
        data.datasets[0].data[3] = stats.http4xxCount;
        data.datasets[0].data[4] = stats.http5xxCount;


        return (
            <DashboardWidgetCard>
                <CardHeader title="HTTP Status Code" />
                <FlexCardContent>

                    <Row>
                        <Col sm={6}>
                            <Doughnut data={data} legend={data.legend} options={data.options} />
                        </Col>
                        <ConstHeightCol sm={6}>
                            <WidgetTable size={"sm"} bordered>
                                <thead>
                                    <tr>
                                        <th>Http Status</th>
                                        <th># of pages</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><HttpStatusHeader index={0}>200</HttpStatusHeader></td>
                                        <td>
                                            <StatusCode200Link to={this.linkForStatusCodeFilter(200)}>{stats.just200Count}</StatusCode200Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><HttpStatusHeader index={1}>301</HttpStatusHeader></td>
                                        <td>
                                            <StatusCode301Link to={this.linkForStatusCodeFilter(301)}>{stats.http301Count}</StatusCode301Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><HttpStatusHeader index={2}>3xx</HttpStatusHeader></td>
                                        <td><ErrorLink
                                            to={this.linkForStatusCodeFilter("300,302,303,304,305,307,307")}>{stats.http3xxCount}</ErrorLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><HttpStatusHeader index={3}>4xx</HttpStatusHeader></td>
                                        <td><ErrorLink
                                            to={this.linkForStatusCodeFilter("400,401,403,404")}>{stats.http4xxCount}</ErrorLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><HttpStatusHeader index={4}>5xx</HttpStatusHeader></td>
                                        <td><ErrorLink
                                            to={this.linkForStatusCodeFilter("500,501,503,504,505")}>{stats.http5xxCount}</ErrorLink>
                                        </td>
                                    </tr>
                                </tbody>
                            </WidgetTable>
                        </ConstHeightCol>
                    </Row>
                </FlexCardContent>

            </DashboardWidgetCard>


        );
    }
}


function mapsStateToProps(state) {
    return { ...state.stats };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchWorkspaceStats: function (workspaceNumber) {
            dispatch(fetchWorkspaceStats(workspaceNumber));
        }
    };
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(IndexStatsWidget));
