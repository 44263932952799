import React, {Component} from "react";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {
    Button,
    Card,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledAlert
} from "reactstrap";
import "./index.css"
import apiFetch from "../util/fetch-utils";
import {toaster} from "evergreen-ui";
import confirm from "../util/components/ConfirmDialog";
import update from 'immutability-helper';
import {CURRENT_WORKSPACE_UPDATED_EVENT, WORKSPACE_DELETED_EVENT} from "../../actions/workspaces";
import defaults, {createNestedObject, getOptionalNested} from "../util/defaults";
import {CountryFlag} from "../Workspace/CountryFlag";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Switch from "@material-ui/core/Switch";
import Container from "@material-ui/core/Container";
import {FormSection} from "./FormSection";
import {GoogleAnalyticsSettings} from "./GoogleAnalyticsSettings";
import deepmerge from "deepmerge"

const wsDefaults = {
    name: "",
    websiteUrl: "",
    users: [],
    countryCode: "",
    crawlerSettings: {
        crawlEnabled: true,
        crawlDelay:0
    }
};

const MAX_NAME_LEN = 50;

class WorkspaceAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ws: defaults(props.ws, wsDefaults),
            selectedUsers: [],
            validation: {},
            login: props.login
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const withWsDefaults = Object.assign({}, nextProps, {ws: defaults(nextProps.ws, wsDefaults)});
        this.setState(withWsDefaults);
    }

    handleChange = ({target}) => {
        let targetValue = createNestedObject(target.name, target.value || target.checked);
        this.updateWorkspace(targetValue);
    };

    handleChange = (event, converter) => {
        let inputValue = event.target.checked || event.target.value;
    
        if (typeof converter === 'function') {
            inputValue = converter(inputValue);
        }
    
    
        let targetValue = createNestedObject(event.target.name, inputValue);
        this.updateWorkspace(targetValue);
    };
    

    updateWorkspace(value) {
        let ws = update(value, { $set: deepmerge(this.state.ws, value) } )
        this.setState({ws});
    }


    handleSelectedUserChance = ({target}) => {
        let selected = [...target.options].filter(o => o.selected).map(o => o.value);
        this.setState({selectedUsers: selected})
        //your logic here
    };

    save = () => {

        let workspace = this.state.ws;

        if (!this.isValid(workspace)) {
            toaster.warning("Save failed", {description: "Please check for invalid fields", duration: 10});
            return;
        }


        apiFetch('/api/workspaces/' + workspace.id, {
            body: JSON.stringify(workspace),
            method: 'PUT',
            headers: {"Content-Type": "application/json"}
        }).then(value => {

            if (value.status === 200) {
                toaster.success("Saved");
                this.props.dispatch({type: CURRENT_WORKSPACE_UPDATED_EVENT, payload: this.state.ws});
            }

            if (value.status !== 200) {
                toaster.danger("Save failed", {
                    description: value.status + " " + value.statusText,
                    duration: 10
                })
            }

        });
    };

    deleteClicked = () => {
        let workspaceName = this.state.ws.name;

        confirm(
            {
                title: "Delete Workspace",
                message: (
                    <div>
                        <p>Are you sure you want delete this workspace?</p>
                        This will prevent all the users to access <strong>{workspaceName}</strong>.
                    </div>
                ),
                confirmText: "Ok",
                confirmColor: "primary",
                cancelColor: "link text-danger"
            })
            .then(val => {
                if (val) {
                    this.deleteWorkspace(this.state.ws.id);
                }
            });
    };

    onAddUserModalExit = (value) => {
        this.setState({addUserModalOpen: false});
        if (value !== undefined) {
            // console.log("wo", this.state.ws)
            this.updateWorkspace({users: [value, ...this.state.ws.users]})
        }
    };

    render() {

        let ws = this.state.ws;
        if (!ws) return null;

        const isOwner = ws.ownerName === this.state.login.username;
        ws = defaults(ws, {name: "", users: [], countryCode: "", websiteUrl: "", analyticsSettings: {tokenId: ""}});
        let ignoreRobots = getOptionalNested(ws, "crawlerSettings.filterConfiguration.ignoreRobotsTxt", false);
        let crawlDelayMillis = getOptionalNested(ws, "crawlerSettings.crawlDelayMillis", 0);


        console.log(ws)

        return (
            <Container>
                <Paper>
                    <Card>
                        <CardContent>
                            {!isOwner && this.state.login.isLoggedIn === true ? (<UncontrolledAlert color="warning">
                                Only workspace owner can change these settings.
                            </UncontrolledAlert>) : null}
                            <Form>
                                <FormGroup row>
                                    <Label for="name" sm={3}>Name</Label>
                                    <Col sm={9}>
                                        <Input type="text" name="name" onChange={this.handleChange} value={ws.name}
                                               invalid={this.state.validation.name} disabled={!isOwner}/>
                                        <FormFeedback>Name length must be between 1
                                            and {MAX_NAME_LEN} characters</FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="owner" sm={3}>Owner</Label>
                                    <Col sm={9}>
                                        <Input type="email" name="owner" disabled defaultValue={ws.ownerName}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="users" sm={3}>Users</Label>
                                    <Col sm={7}>
                                        <Input type="select" name="users" id="users" disabled={!isOwner} multiple
                                               value={this.state.selectedUsers}
                                               onChange={this.handleSelectedUserChance}>
                                            {ws.users.map((u, i) => <option key={i}>{u}</option>)}
                                        </Input>
                                        <FormFeedback>Oh noes! that name is already taken</FormFeedback>
                                    </Col>
                                    <Col sm={2}>
                                        <Button color="primary" size="sm" block onClick={() => this.openAddUserModal()}
                                                disabled={!isOwner}>Add</Button>
                                        <Button color="primary" size="sm" block onClick={() => this.removeUser()}
                                                disabled={this.state.selectedUsers.length < 1}>Remove</Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="country-code">
                                    <Label for="countryCode" sm={3}>Country Code</Label>
                                    <Col sm={9}>
                                        <Input type="text" name="countryCode" placeholder="GB, IT, etc..."
                                               disabled={!isOwner} onChange={this.handleChange} value={ws.countryCode}/>
                                        <CountryFlag countryCode={ws.countryCode}/>
                                    </Col>
                                </FormGroup>
                                <FormSection label={"Crawler Settings"}>
                                    <FormGroup row>
                                        <Label for="websiteUrl" sm={3}>Website URL</Label>
                                        <Col sm={9}>
                                            <Input type="text" name="websiteUrl"
                                                   placeholder="https://example.com" onChange={this.handleChange}
                                                   value={ws.websiteUrl}
                                                   disabled={!isOwner}
                                                   invalid={this.state.validation.websiteUrl}/>
                                            <FormFeedback>Please insert a valid URL including protocol. Example:
                                                https://example.com</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="crawlIntervalDays" sm={3}>Crawl Frequency</Label>
                                        <Col sm={9}>
                                            <Input type="select" name="crawlIntervalDays" disabled={!isOwner}>
                                                <option>Daily</option>
                                                <option>Weekly</option>
                                                <option>Fortnightly</option>
                                                <option>Monthly</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="crawlDelay" sm={3}>Crawl Delay (seconds)</Label>
                                        <Col sm={9}>
                                            <Input type="text" 
                                            name="crawlerSettings.crawlDelayMillis" 
                                            onChange={e => this.handleChange(e, value => parseFloat(value) * 1000)}
                                            value={crawlDelayMillis/1000}
                                                invalid={this.state.validation.crawlDelay} disabled={!isOwner}/>
                                            <FormFeedback>Crawl delay must be between 0 and 60 seconds</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="websiteUrl" sm={3}>Ignore Robots.txt</Label>
                                        <Col sm={9}>
                                            <Switch
                                                checked={ignoreRobots}
                                                name="crawlerSettings.filterConfiguration.ignoreRobotsTxt"
                                                onChange={this.handleChange}
                                                color="primary"
                                            />
                                        </Col>
                                    </FormGroup>
                                </FormSection>

                                <GoogleAnalyticsSettings setAnalyticsView={this.setAnalyticsView}
                                                         currentWorkspace={this.state.ws}/>

                                <Button className={"marginbottom"} color="primary" block onClick={() => this.save()}
                                        disabled={!isOwner}>Save</Button>
                                <Button className={"marginbottom"} outline color="danger"
                                        onClick={() => this.deleteClicked()} disabled={!isOwner}>Delete
                                    Workspace</Button>
                                <AddUserModalForm open={this.state.addUserModalOpen} onExit={this.onAddUserModalExit}/>

                            </Form>
                        </CardContent>
                    </Card>
                </Paper>
            </Container>


        );
    }

    setAnalyticsView = (view) => {
        console.log("seAnaVire", view)
        this.updateWorkspace({
            analyticsSettings: {
                tokenId: view.tokenId,
                tokenName: view.tokenName,
                viewId: view.value,
                viewName: view.viewName,
                analyticsAccountOwner: view.analyticsAccountOwner
            }
        });
    };

    removeUser() {

        let workspaceName = this.state.ws.name;

        confirm(
            {
                title: "Remove workspace users",
                message: (
                    <div>
                        <p>Are you sure you want to remove the following users?</p>
                        <ul>
                            {this.state.selectedUsers.map((username, i) => <li key={i}>{username}</li>)}
                        </ul>
                        This will prevent the users to access <strong>{workspaceName}</strong>.
                    </div>
                ),
                confirmText: "Ok",
                confirmColor: "primary",
                cancelColor: "link text-danger"
            })
            .then(val => {
                if (val) {
                    let remainingUsers = this.state.ws.users.filter(e => this.state.selectedUsers.indexOf(e) < 0);
                    let ws = update(this.state.ws, {$merge: {users: remainingUsers}});
                    this.setState({selectedUsers: [], ws});
                }
            });
    }

    openAddUserModal() {
        this.setState({addUserModalOpen: true});
    }

    isValid = (workspace) => {
        const validUrl = workspace.websiteUrl === null || workspace.websiteUrl.length === 0 || validURL(workspace.websiteUrl);
        const validName = workspace.name.length > 0 && workspace.name.length <= MAX_NAME_LEN;
        const validCrawlDelay = (!workspace.crawlerSettings.crawlDelayMillis || workspace.crawlerSettings.crawlDelayMillis >= 0) && workspace.crawlerSettings.crawlDelayMillis <= 60000;

        this.setState(
            {
                validation:
                    {
                        name: !validName,
                        websiteUrl: !validUrl,
                        crawlDelay: !validCrawlDelay
                    }
            });
        return validUrl && validName && validCrawlDelay;
    };

    deleteWorkspace(id) {

        apiFetch('/api/workspaces/' + id, {
            method: 'DELETE',
            headers: {"Content-Type": "application/json"}
        }).then(value => {

            if (value.status === 200) {
                toaster.success("Workspace deleted");
                this.props.history.push("/");
                this.props.dispatch({type: WORKSPACE_DELETED_EVENT, payload: this.state.ws});
            }

            if (value.status !== 200) {
                toaster.danger("Delete failed", {
                    description: value.status + " " + value.statusText,
                    duration: 10
                })
            }

        });
    }
}

const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
function validURL(str) {
    return pattern.test(str);
}

class AddUserModalForm extends Component {


    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: ""
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({value: "", ...nextProps});
    }

    okButton = () => {
        this.props.onExit(this.state.value);
        this.hide();
    };

    cancelButton = () => {
        this.props.onExit();
        this.hide();
    };

    hide = () => {
        this.setState({open: false});
    };

    handleChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };

    render() {
        return (
            <Modal isOpen={this.state.open} toggle={this.hide} className={this.props.className}>
                <ModalHeader toggle={this.hide}>Analytics View</ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label for="value" sm={3}>Username</Label>
                        <Col sm={9}>
                            <Input type="text" name="value" placeholder="user@example.com" onChange={this.handleChange}
                                   value={this.state.value}/>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.okButton}>Ok</Button>{' '}
                    <Button color="secondary" onClick={this.cancelButton}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}


function mapsStateToProps(state) {
    return {"ws": state.workspaces.current, "login": state.login.userDetails};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(WorkspaceAdmin));
