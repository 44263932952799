import apiFetch from "../components/util/fetch-utils";

export const FETCH_WORKSPACES_SUCCESS = "FETCH_WORKSPACES_SUCCESS";
export const FETCH_WORKSPACES_FAILURE = "FETCH_WORKSPACES_FAILURE";
export const WORKSPACE_SELECTED_EVENT = "WORKSPACE_SELECTED_EVENT";
export const CURRENT_WORKSPACE_UPDATED_EVENT = "CURRENT_WORKSPACE_UPDATED_EVENT";
export const WORKSPACE_CREATED_EVENT = "WORKSPACE_CREATED_EVENT";
export const WORKSPACE_DELETED_EVENT = "WORKSPACE_DELETED_EVENT";
export const CURRENT_WORKSPACE_FETCH_SUCCESS = "CURRENT_WORKSPACE_FETCH_SUCCESS";
export const CURRENT_WORKSPACE_FETCH_FAILURE = "CURRENT_WORKSPACE_FETCH_FAILURE";

export function fetchWorkspaces() {
    return dispatch => {
        apiFetch('/api/workspaces')
            .then(response => {
                if (response.status === 200) {
                    response.json().then(responseJson => {
                        dispatch({type: FETCH_WORKSPACES_SUCCESS, payload: responseJson});
                    });
                } else {
                    dispatch({type: FETCH_WORKSPACES_FAILURE, error: response});
                }
            });
    }
}

export function fetchWorkspaceById(id) {
    return dispatch => {
        apiFetch(`/api/workspaces/${id}`)
            .then(response => {
                if (response.status === 200) {
                    response.json().then(responseJson => {
                        dispatch({type: CURRENT_WORKSPACE_FETCH_SUCCESS, payload: responseJson});
                    });
                } else {
                    dispatch({type: CURRENT_WORKSPACE_FETCH_FAILURE, error: response});
                }
            });
    }
}
