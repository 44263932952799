import {combineReducers} from 'redux'

import stats from "./stats";
import monitoredUri from "./monitoredUri";
import login, {LOGOUT_SUCCESS} from "./login";
import currentMonitoredUri from "./currentMonitoredUri";
import fieldEditor from "../components/RecommendationEditor/redux/reducer"
import workspaces from "./workspaces";
import addRecommendations from "./addRecommendations";
import plans from "./plans";
import { crawls } from './crawls';

export const RESET_STATE = 'RESET_STATE';

const appReducer = combineReducers({
    monitoredUri, stats, login, currentMonitoredUri,
    fieldEditor, workspaces, addRecommendations, plans, crawls
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS || action.type === RESET_STATE) {
        state = undefined
    }
    return appReducer(state, action)
};


export default rootReducer;
