import React, {Component} from "react";
import DiffPopover from "../Diffpopover";
import {Badge, Button, UncontrolledTooltip} from "reactstrap";
import {FaEdit} from "react-icons/fa";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";


const StyledTableCell = styled(TableCell)`
     @media only screen and (max-width: 768px) {
      padding: 14px .5em 14px .5em !important;
    }
`;

class PageSnapshotField extends Component {
    render() {
        const cur = this.props.fieldValueCur;
        let ref = this.props.fieldValueRef;

        //This can override cur to be the visualized text in case we need to present a
        //different text than the one that gets compared ( for now we have only destinationUri
        const displayedText = this.props.displayedText || cur;


        //If null, we don't want the difference badge as null indicate no recommendation provided
        if (!ref) ref = cur;

        const ignoredBadge = needIgnoreBadge(this.props.diffDisabled, this.props.fieldValueRef) ?
            <IgnoredBadge>ignored</IgnoredBadge> : null;
        return (
            <TableRow>
                <StyledTableCell style={{width: "15%"}}>
                    <strong>{this.props.fieldName}</strong>
                    {ignoredBadge}
                </StyledTableCell>
                <StyledTableCell style={{width: "80%"}}>
                    <DiffPopover diffDisabled={this.props.diffDisabled}
                                 name={this.props.fieldName}
                                 inputA={ref}
                                 inputB={cur}/>
                    <span>{displayedText}</span>
                </StyledTableCell>
                <StyledTableCell style={{width: "3em"}}>
                    <EditButton {...this.props}/>
                </StyledTableCell>
            </TableRow>
        )
    }
}


class PageSnapshotListField extends Component {

    render() {

        // if (!this.props.diffDisabled)
        //     console.log("Rendering:", this.props);

        const cur = Array.isArray(this.props.fieldValueCur) ? this.props.fieldValueCur.slice(0) : [];
        const ref = this.props.diffDisabled ? cur : (this.props.fieldValueRef || cur);

        if (ref.length > cur.length) {
            const diff = (ref.length - cur.length);
            for (let i = 0; i < diff; i++) {
                cur.push(null);
            }
        }


        const ignoredBadge = needIgnoreBadge(this.props.diffDisabled, this.props.fieldValueRef) ?
            <IgnoredBadge>ignored</IgnoredBadge> : null;


        return (
            <TableRow>
                <StyledTableCell style={{width: "10%"}}>
                    <strong>{this.props.fieldName}</strong>
                    {ignoredBadge}

                </StyledTableCell>
                <StyledTableCell>
                    <div className={"no-overflow"}>

                        {
                            cur.map((value, index) => {
                                return (
                                    <div key={'PageSnapshotListField' + index}>
                                        <DiffPopover name={this.props.fieldName}
                                                     inputA={ref[index]}
                                                     inputB={value}/>
                                        <span>{value}</span>
                                    </div>

                                )
                            })
                        }
                    </div>
                </StyledTableCell>
                <StyledTableCell style={{width: "3em"}}>
                    <EditButton {...this.props}/>
                </StyledTableCell>
            </TableRow>
        )
    }


}


PageSnapshotListField.defaultProps = {
    fieldName: ""
};


function EditButton(props) {

    if ('undefined' !== typeof props.beginEdit) {
        return (
            <Button title="Edit recommendation"
                    color="primary" size={"sm"} onClick={() => {
                props.beginEdit(props.fieldId)
            }}>
                <FaEdit/>
            </Button>
        );
    } else {
        return null;
    }
}


class IgnoredBadge extends Component {

    static id = 0;

    id = IgnoredBadge.id++;

    render() {
        return (

            <div>
                <UncontrolledTooltip placement="right"
                                     target={"ignoredTooltip" + this.id}>
                    No value was recommended for this field
                </UncontrolledTooltip>
                <Badge id={"ignoredTooltip" + this.id}>ignored</Badge>
            </div>
        );
    }
}

function needIgnoreBadge(diffDisabled, fieldValueRef) {
    return !diffDisabled &&
        (fieldValueRef === undefined || fieldValueRef == null);
}


export {PageSnapshotField, PageSnapshotListField}
