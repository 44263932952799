import React, { useState } from 'react';
import {
    Box,
    Button,
    Collapse,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Status from './Status';
import Progress from './Progress';
import Controls from './Controls';
import CrawlDetails from './CrawlDetails';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    statusBox: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    spacer: {
        marginRight: theme.spacing(2),
    },
    flexGrow: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

function CrawlerInfo({ lastCrawlId, onCrawlStart, crawlStarting }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const crawlStatus = useSelector((state) => state.crawls.crawls[lastCrawlId]);

    if (!crawlStatus || !crawlStatus.status) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    const isIdle = crawlStatus.status.pendingPages === 0 && crawlStatus.status.visitedPages === 0;
    const isCrawling = !isIdle && crawlStatus.status.pendingPages > 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Box className={classes.statusBox}>
                <Status isCrawling={isCrawling} visitedPages={crawlStatus.status.visitedPages} />
                <Box className={classes.flexGrow}>
                    <Progress progress={isIdle ? 100 : (crawlStatus.status.visitedPages / (crawlStatus.status.visitedPages + crawlStatus.status.pendingPages)) * 100} />
                </Box>
                <Controls isCrawling={isCrawling} onCrawlStart={onCrawlStart} crawlStarting={crawlStarting} />
            </Box>
            <Box textAlign="center" mt={2}>
                <Button variant="contained" color="primary" onClick={handleExpandClick}>
                    {expanded ? "Show Less" : "Show More"}
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CrawlDetails crawlStatus={crawlStatus} />
            </Collapse>
        </div>
    );
}

export default CrawlerInfo;
