import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import {BillingIntervalButtons} from "./BillingIntervalButtons";
import ProductCard from "./ProductCard";
import {fetchPlans} from "../../actions/products";
import fetchLoginStatus from "../util/login-status";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(10)
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(1, 0, 6),
    }
}));

export default function Pricing() {
    const [priceInterval, setPriceInterval] = useState("month")
    const classes = useStyles();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const isLoggedId = useSelector((state) => state.login.userDetails.isLoggedIn);
    const plans = useSelector(state => state.plans);

    useEffect(() => {
        if (!plans.fetched) {
            dispatch(fetchPlans());
        }
        if (!login.userDetails.fetchAttempted) {
            dispatch(fetchLoginStatus(login));
        }
    }, [login, dispatch, plans.fetched])

    function handlePriceIntervalChange(event, newInterval) {
        if (newInterval) setPriceInterval(newInterval);
    }

    return (
        <div className={classes.root}>
            <HeroContent/>
            <BillingIntervalButtons priceInterval={priceInterval}
                                    handlePriceIntervalChange={handlePriceIntervalChange}/>
            <Products/>
        </div>
    );

    function HeroContent() {
        return <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                {isLoggedId ? "Change Subscription" :
                    "Pricing"
                }
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
                {isLoggedId ? "You can change your subscription at any time. We'll transfer your remaining balance into your new subscription!" :
                    "Select the plan that fits you the most. We're here to unlock your full potential!"
                }
            </Typography>
        </Container>;
    }

    function Products() {
        let cardSizes = {xs: 12, sm: 6, md: 6, lg: 3};
        return <Container maxWidth="lg" component="main">
            <Grid id="plansContainer" container spacing={3} alignItems="flex-end">
                {plans.data.map((product, i) =>
                    <ProductCard key={i} product={product}
                                 priceInterval={priceInterval}
                                 cardSizes={cardSizes}
                    />
                )}
            </Grid>
        </Container>;
    }

}

