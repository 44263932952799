import React from 'react';
import {
    Badge,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    UncontrolledDropdown
} from 'reactstrap';
import AccountAvatarMenu from "../Login/AccountAvatarMenu";
import connect from "react-redux/es/connect/connect";

import "./style.css"
import WorkspaceSelector from "./WorkspaceSelector";
import {withRouter} from "react-router";
import {fetchWorkspaces} from "../../actions/workspaces";
import {generateUrlForWorkspace} from "../Workspace/common";
import DashboardLink from "./DashboardLink";


class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isOpen: false};
    }

    needFetchWorkspace = () => {
        return this.props.login.userDetails.isLoggedIn === true && !this.props.workspaces.fetched;
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.needFetchWorkspace()) {
            this.props.dispatch(fetchWorkspaces());
        }
    }


    toggle = () => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }));
    };

    render() {

        // let addUrlButton = null;
        //
        //
        // // if (this.props.workspaces.fetched) {
        // //     addUrlButton = this.initAddUrlButton();
        // // }

        let workspaceNumber = this.props.workspaces.current.seqNumber;

        let workspaceOptions = null;

        if (workspaceNumber !== undefined) {
            let addUrlUrl = "/#" + generateUrlForWorkspace(workspaceNumber) + "/add-url";
            let admin = "/#" + generateUrlForWorkspace(workspaceNumber) + "/admin";
            let download = "/api/monitored-uris/download?workspaceNumber=" + workspaceNumber;
            let downloadActions = "/api/actions/download?workspaceNumber=" + workspaceNumber;


            workspaceOptions = (
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Workspace Options
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem href={admin}>Workspace Settings</DropdownItem>
                        <DropdownItem href={addUrlUrl}>Bulk Add Recommendation</DropdownItem>
                        <DropdownItem href={download}>Export as Excel spreadsheet</DropdownItem>
                        <DropdownItem href={downloadActions}>Download Actions <Badge color={"primary"} pill>beta</Badge></DropdownItem>
                        {/*<DropdownItem>*/}
                        {/*Edit Workspace*/}
                        {/*</DropdownItem>*/}
                        {/*<DropdownItem divider/>*/}
                        {/*<DropdownItem>*/}
                        {/*Delete workspace*/}
                        {/*</DropdownItem>*/}
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        }


        return (
            <div id="navbar">
                <Navbar color="dark" dark expand="md">

                    <NavbarBrand href="/#/">Workspaces</NavbarBrand>
                    <DashboardLink workspaces={this.props.workspaces}/>


                    <NavbarToggler onClick={this.toggle}/>

                    <Collapse isOpen={this.state.isOpen} navbar>
                        <WorkspaceSelector/>
                        <Nav className="ml-auto" navbar>
                            {workspaceOptions}
                            <NavItem>
                                <AccountAvatarMenu/>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }


}


function mapsStateToProps(state) {
    return {"login": state.login, "workspaces": state.workspaces};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(NavBar));
