import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown} from "reactstrap";
import {connect} from "react-redux";
import {generateUrlForWorkspace} from "../Workspace/common";
import {withRouter} from "react-router";


class WorkspaceSelector extends React.Component {


    render() {

        const workspaces = this.props.workspaces;
        if (!workspaces.fetched) return null;

        let cur = workspaces.current;

        let workspacesElement = workspaces.data.map((curWs, index) =>
            <DropdownItem key={index}>
                <NavItem>
                    <NavLink href={"/#" + this.generateUrl(curWs.seqNumber)}>{curWs.name}</NavLink>
                </NavItem>
            </DropdownItem>);


        return (

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    {cur.name}
                </DropdownToggle>
                <DropdownMenu>
                    {workspacesElement}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    generateUrl(workspacesNumber) {

        const curUrl = this.props.location.pathname;

        let pattern = /(.*\/ws\/)(\d+)(\/.*)/;

        //if we are inside a subpage of a workspace eg: /ws/3/add-url we just want to change the workspace number and stay there
        if (curUrl.match(pattern)) {
            return curUrl.replace(pattern, "$1" + workspacesNumber + "$3");
        }

        return generateUrlForWorkspace(workspacesNumber);
    }

}

function mapsStateToProps(state) {
    return {"login": state.login, "workspaces": state.workspaces};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(WorkspaceSelector));




