import {FETCH_ISSUE_TREND_SUCCESS, FETCH_WORKSPACE_STATS_SUCCESS} from "../actions/stats";

const initialState = {
    workspaceStats: {fetched: false},
    issueTrendFetched: 0,
    data: [],
    totalElements: 0,
    diffs: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKSPACE_STATS_SUCCESS:
            return Object.assign({}, state, {
                workspaceStats: {
                    ...action.payload,
                    fetched: true
                }
            });
        case FETCH_ISSUE_TREND_SUCCESS:
            return Object.assign({}, state, {issueTrend: action.payload, issueTrendFetched: true});
        default:
            return state
    }
}


