import {
    CURRENT_WORKSPACE_UPDATED_EVENT,
    CURRENT_WORKSPACE_FETCH_SUCCESS,
    CURRENT_WORKSPACE_FETCH_FAILURE,
    FETCH_WORKSPACES_FAILURE,
    FETCH_WORKSPACES_SUCCESS,
    WORKSPACE_CREATED_EVENT,
    WORKSPACE_DELETED_EVENT,
    WORKSPACE_SELECTED_EVENT
} from "../actions/workspaces";
import { toaster } from "evergreen-ui";
import update from "immutability-helper"
import { GOOGLE_ANALYTICS_TOKEN_REMOVED } from "./login";
import { CRAWL_STARTING, START_CRAWL_FAILURE, START_CRAWL_SUCCESS } from "../actions/crawls";

const initialState = {
    current: {
        name: "",
        websiteUrl: "",
        users: [],
        countryCode: "",
        analyticsSettings: {},
        lastCrawls: {}
    },
    fetched: false,
    data: []
};


export default (state = initialState, action) => {


    function removeTokenFromAllWorkspaces(tokenId) {
        return state.data.map(workspace => {
            if (workspace.analyticsSettings && workspace.analyticsSettings.tokenId === tokenId) {
                workspace.analyticsSettings = null;
            }
            return workspace;
        });
    }

    function removeTokenFromCurrentWorkspace(tokenId) {
        if (state.current.analyticsSettings && state.current.analyticsSettings.tokenId === tokenId) {
            return null;
        }
        return state.current.analyticsSettings;
    }

    switch (action.type) {
        case WORKSPACE_DELETED_EVENT:
            let deletedIndex = state.data.findIndex(it => it.seqNumber === action.payload.seqNumber);

            return Object.assign({}, state, { current: initialState.current },
                { data: state.data.filter((_, i) => i !== deletedIndex) }
            );
        case WORKSPACE_CREATED_EVENT:
            return Object.assign({}, state,
                {
                    data: update(state.data, { $push: [action.payload] })
                });
        case CURRENT_WORKSPACE_UPDATED_EVENT:
            let index = state.data.findIndex(it => it.seqNumber === action.payload.seqNumber);
            return Object.assign({}, state,
                { current: action.payload },
                {
                    data: update(state.data, { [index]: { $set: action.payload } })
                });
        case GOOGLE_ANALYTICS_TOKEN_REMOVED:
            let newWorkspaces = removeTokenFromAllWorkspaces(action.id);
            let newCurrentAnalyticsSettings = removeTokenFromCurrentWorkspace(action.id);
            return Object.assign({}, state, {
                data: newWorkspaces, current: {
                    analyticsSettings: newCurrentAnalyticsSettings
                }
            });
        case WORKSPACE_SELECTED_EVENT:
            if (!state.fetched) {
                return state;
            }
            let workspaceNumber = parseInt(action.payload, 10);
            const find = state.data.find(it => it.seqNumber === workspaceNumber);

            if (!find) return state;

            return Object.assign({}, state, { current: find });
        case FETCH_WORKSPACES_SUCCESS:
            return Object.assign({}, state, { data: action.payload, fetched: true });
        case FETCH_WORKSPACES_FAILURE:
            toaster.danger(`Unable to fetch workspaces: ${action.error.statusText}`);
            return Object.assign({}, state, { fetched: false });
        case CURRENT_WORKSPACE_FETCH_SUCCESS:
            return Object.assign({}, state, { current: action.payload });
        case CURRENT_WORKSPACE_FETCH_FAILURE:
            toaster.danger(`Unable to fetch current workspace: ${action.error.statusText}`);
            return state;
        case CRAWL_STARTING:
            return Object.assign({}, state,
                {
                    current: {
                        ...state.current,
                        crawlStarting: true
                    }
                });
        case START_CRAWL_SUCCESS:
            return Object.assign({}, state,
                {
                    current: {
                        ...state.current,
                        lastCrawls: [
                            { id: action.payload.crawlId },
                            ...state.current.lastCrawls
                        ],
                        crawlStarting: false
                    }
                });
        case START_CRAWL_FAILURE:
            return Object.assign({}, state,
                {
                    current: {
                        crawlStarting: false
                    }
                });

        default:
            return state;
    }
}
