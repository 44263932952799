import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Container, FormHelperText, Link, makeStyles, TextField, Typography} from '@material-ui/core';
import Page from "../common/Page";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router";
import apiFetch from "../util/fetch-utils";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3)
    },
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ForgottenPassword = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Page className={classes.root} title="Register">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Paper className={classes.paper}>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                                })
                            }
                            onSubmit={onSubmit}
                        >
                            {(all) => <Content {...all}/>}
                        </Formik>
                    </Paper>
                </Container>
            </Box>
        </Page>
    );

    function Content(props) {
        if (props.status) {
            return <div>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    If the email you provided is associated with an account,
                    you should find an email in your inbox, with the instructions to reset your
                    password!
                </Alert>
                <Box my={2}>
                    <Button
                        color="primary"
                        onClick={() => history.push("/login")}
                        fullWidth
                        type="submit"
                        variant="contained"
                    >
                        Go back to Log in
                    </Button>
                </Box>

            </div>
        }

        return Form(props);
    }

    function Form({
                      handleSubmit, touched, errors, handleBlur,
                      handleChange, values, isSubmitting, status
                  }) {
        return <form onSubmit={handleSubmit}>
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h4"
                >
                    Forgotten your password?
                </Typography>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    Don't worry, that's not a problem at all! We'll send you an email with the instructions to reset it.
                </Typography>
            </Box>

            <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
            />
            {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>
                    {errors.policy}
                </FormHelperText>
            )}
            <Box my={2}>
                <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    Send
                </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
                Remembered it already?{' '}
                <Link component={RouterLink} to="/login">Log in</Link>
            </Typography>
        </form>;
    }

    function onSubmit(values, actions) {


        apiFetch("/api/auth/forgotten-password", {
            method: 'POST',
            headers: {"Content-Type": "application/text"},
            body: values.email
        }).then(value => {
            if (!value.ok) {
                throw new Error();
            }
            actions.setStatus(true);
        }).finally(() => {
            actions.setSubmitting(false);
        });

    }

};

export default ForgottenPassword;
