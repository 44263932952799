import React from "react";
import {FaInfoCircle} from "react-icons/fa";
import styled from "styled-components";
import {Button, UncontrolledCollapse, UncontrolledTooltip, Table} from "reactstrap";

const StyledTable = styled(Table)`
    
      margin-bottom: 0;
      
      tbody  {
        vertical-align: middle;
        text-align: center;
      }
      
      thead th {
        font-size: small;
        text-align: center;
      }

      td  {
        text-align: center;
      }           
`;

const LinkButton = styled(Button)`
  margin-left: 0;
  padding :0;
`;


class ExampleUsage extends React.Component {
    render() {

        let excelParagraph = "";

        if (this.props.isFileUpload) {
            excelParagraph = <p>You can upload an Excel spreadsheet with one or more Sheets (tabs) containing a list of uris and their recommended metadata (Title, Meta Description and H1)</p>;
        }

        return (
            <div>
                <LinkButton color="link" id="toggler" style={{marginBottom: '1rem'}}>
                    Example Usage
                </LinkButton>
                <UncontrolledCollapse toggler="#toggler">

                    {excelParagraph}
                    <p>The recommended metadata are <i>optional</i>. If no value is specified (see example below) it will leave the current recommendation as it is.</p>
                    <p>If you need to <b>REMOVE</b> an existing recommendation you need to specify "&lt;REMOVE-RECOMMENDATION&gt;" as value. Example below.</p>
                    <p>You can use this page to add other URLs you want to keep track of, but please note that if you add a URL that is already part of one of your other workspaces, it will not be
                        moved
                        here. We will still update its value.</p>


                    <h4>Example</h4>

                    <StyledTable bordered responsive >
                        <thead>
                        <tr>
                            <th width="6%"></th>
                            <th>A
                                <FaInfoCircle id="urlTooltip"/>
                                <UncontrolledTooltip placement="top" target="urlTooltip">
                                    The URL for which we want to provide recommendation.
                                </UncontrolledTooltip>
                            </th>
                            <th>B
                                <FaInfoCircle id="dstUrlTooltip"/>
                                <UncontrolledTooltip placement="bottom" target="dstUrlTooltip">
                                    <p>The expected destination URL (useful in case we want to make sure a redirect is implemented)</p>
                                    <p>
                                        <small>Leave blank in case no recommendation is necessary</small>
                                    </p>
                                </UncontrolledTooltip>
                            </th>
                            <th>C
                                <FaInfoCircle id="titleTooltip"/>
                                <UncontrolledTooltip placement="bottom" target="titleTooltip">
                                    <p>The Title we recommend for this page</p>
                                    <p>
                                        <small>Leave blank in case no recommendation is necessary</small>
                                    </p>
                                </UncontrolledTooltip>
                            </th>
                            <th>D
                                <FaInfoCircle id="metaDescrTooltip"/>
                                <UncontrolledTooltip placement="bottom" target="metaDescrTooltip">
                                    <p>The Meta Description we recommend for this page</p>
                                    <p>
                                        <small>Leave blank in case no recommendation is necessary</small>
                                    </p>
                                </UncontrolledTooltip>
                            </th>
                            <th>E
                                <FaInfoCircle id="h1Tooltip"/>
                                <UncontrolledTooltip placement="bottom" target="h1Tooltip">
                                    <p>The H1 we recommend for this page</p>
                                    <p>
                                        <small>Leave blank in case no recommendation is necessary</small>
                                    </p>
                                </UncontrolledTooltip>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>https://url1</td>
                            <td>https://destination1
                            </td>
                            <td>Example title</td>
                            <td>Example Meta Description</td>
                            <td>Example H1</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>https://url2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><small>&lt;REMOVE-RECOMMENDATION&gt;</small></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>https://url3</td>
                            <td>https://destination2</td>
                            <td>A title</td>
                            <td>A Meta Description</td>
                            <td>This is an H1</td>
                        </tr>
                        </tbody>
                    </StyledTable>
                    <br/>

                    <p> The empty values in: B2, C2, D2 will leave the current recommendation unchanged if present or no recommendation will be added</p>
                    <p>The value in E2, will remove the existing H1 recommendation if present</p>
                    <hr/>
                </UncontrolledCollapse>
            </div>

        );
    }
}


export default ExampleUsage;
