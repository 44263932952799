import {MONITORED_URI_REFERENCE_VALUE_UPDATED} from "../actions/monitoredUri";

export const CURRENT_MONITORED_URI_FETCHED = "CURRENT_MONITORED_URI_FETCHED";
export const CURRENT_MONITORED_URI_PERFORMANCES_FETCHED = "CURRENT_MONITORED_URI_PERFORMANCES_FETCHED";
export const CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED = "CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED";
export const CURRENT_MONITORED_URI_SNAPSHOT_FETCH_STARTED = "CURRENT_MONITORED_URI_SNAPSHOT_FETCH_STARTED";
export const CURRENT_MONITORED_URI_SNAPSHOT_FETCH_SUCCESS = "CURRENT_MONITORED_URI_SNAPSHOT_FETCH_SUCCESS";
export const CURRENT_MONITORED_URI_SNAPSHOT_FETCH_404 = "CURRENT_MONITORED_URI_SNAPSHOT_FETCH_404";
export const CURRENT_MONITORED_URI_CHANGES_FETCHED = "CURRENT_MONITORED_URI_CHANGES_FETCHED";
export const CURRENT_MONITORED_SELECT_SNAPSHOT = "CURRENT_MONITORED_SELECT_SNAPSHOT";
export const CURRENT_MONITORED_URI_CHANGED = "CURRENT_MONITORED_URI_CHANGED";


export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETED = "COMPLETED";
export const ERROR_404 = "ERROR_404";

const initialState = {
    numDaysHistory: 700,
    curSnapshotIndex: -1,
    pageSnapshotsMap: {},
    pageChanges: [],
    pagePerformances: [],
    inboundLinks: {content: []},
    lastCrawl: {},
    currentValue: {
        "uri": "",
        "time": 0,
        "title": "",
        "h1s": [],
        "h2s": [],
        "metaDescriptions": [],
        "canonicals": []
    },
    recommendation: {
        "uri": "",
        "time": 0,
        "title": "",
        "h1s": [],
        "h2s": [],
        "metaDescriptions": [],
        "canonicals": []
    }

};


export default (state = initialState, action) => {
    // console.log("Current Monitored Uri Event:", action)
    // console.log("State", state)
    switch (action.type) {

        case MONITORED_URI_REFERENCE_VALUE_UPDATED:
            if (action.monitoredUriId === state.id) {
                let newRef = Object.assign({}, state.recommendation, action.newValue);
                return Object.assign({}, state, {recommendation: newRef});
            }
            return state;

        case CURRENT_MONITORED_URI_CHANGED:
            return initialState;

        case CURRENT_MONITORED_SELECT_SNAPSHOT:
            return Object.assign({}, state, {
                curSnapshotDate: action.curSnapshotDate,
                lastCrawl: initialState.lastCrawl
            });
        case CURRENT_MONITORED_URI_CHANGES_FETCHED:
            //We are removing the first element as it's first crawl. (that is until we filter by date)
            return Object.assign({}, state, {pageChanges: action.payload.slice(1)});

        case CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED:
            return Object.assign({}, state, {inboundLinks: action.payload.inboundLinks});


        case CURRENT_MONITORED_URI_SNAPSHOT_FETCH_STARTED:

            return Object.assign({}, state, {
                    pageSnapshotsMap: updatePageSnapshotMap(state, action.curSnapshotDate, {fetchStatus: IN_PROGRESS})
                }
            );

        case CURRENT_MONITORED_URI_SNAPSHOT_FETCH_404:
            return Object.assign({}, state, {
                    pageSnapshotsMap: updatePageSnapshotMap(state, action.curSnapshotDate, {fetchStatus: ERROR_404})
                }
            );

        case CURRENT_MONITORED_URI_SNAPSHOT_FETCH_SUCCESS:
            return Object.assign({}, state, {
                    pageSnapshotsMap: updatePageSnapshotMap(state, action.curSnapshotDate, {...action.value, fetchStatus: COMPLETED})
                }
            );

        case CURRENT_MONITORED_URI_PERFORMANCES_FETCHED:

            return Object.assign({}, state, {
                pagePerformances: addElementToArray(state.pagePerformances, action.payload)
            });

        case CURRENT_MONITORED_URI_FETCHED:
            if (!action.payload.recommendation) action.payload.recommendation = {};

            let curSnapshotDate = new Date().toISOString().split("T")[0];

            const currentValuePresent = action.payload.currentValue;

            if (currentValuePresent) {
                currentValuePresent.lastCrawl = action.payload.lastCrawl;
            }
            action.payload.lastCrawl = undefined; //avoiding depending on value that is not present for previous snapshots

            if (currentValuePresent) {
                curSnapshotDate = new Date(currentValuePresent.createDate).toISOString().split("T")[0];
            }

            return Object.assign({}, state, {
                    ...action.payload,
                    lastSnapshotDate: curSnapshotDate,
                    curSnapshotDate: curSnapshotDate,
                    pageSnapshotsMap: updatePageSnapshotMap(state, curSnapshotDate,
                        {...currentValuePresent, fetchStatus: currentValuePresent ? COMPLETED : ERROR_404}
                    )
                }
            );
        default:
            return state
    }
}

function updatePageSnapshotMap(state, dateString, value) {
    //dateString format: 2019-01-01
    return {
        ...state.pageSnapshotsMap, [dateString]: value
    };
}

function addElementToArray(array, value) {
    return [
        ...array, value
    ]
}


