import {FIELD_EDIT_BEGIN, FIELD_EDIT_ERROR, FIELD_EDIT_HIDE} from "./actions";

import {toaster} from "evergreen-ui";


const initialState = {
    isOpen: false
};


export default (state = initialState, action) => {

    switch (action.type) {
        case FIELD_EDIT_HIDE:
            return Object.assign({}, state, {
                isOpen: false
            });
        case FIELD_EDIT_BEGIN:
            return Object.assign({}, state, {
                isOpen: true,
                ...action.payload
            });

        case FIELD_EDIT_ERROR:
            const response = action.payload;
            toaster.danger('Unable to update recommended value', {description: response.status + ": " + response.statusText});
            return Object.assign({}, state, {
                isOpen: false
            });
        default:
            return state
    }
}

