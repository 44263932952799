import React from 'react'
import styled from "styled-components";
import {Container} from "reactstrap";


const ErrorContainer = styled(Container)`
    text-align: center;
    h1{    
     text-align: center;
     font-size: 10em;
    }
    span{
      font-size: 2em;
    }
`;

const SomethingWentWrong = props => {
    return (
        <ErrorContainer>
            <h1>:(</h1>
            <h2>This is awkward... Something went wrong.</h2>
            <p>We encountered an error while loading this page. Please refresh the page by pressing <b>F5</b> and try again.</p>
            <p>If the problem persist contact us at: <a
                href="mailto:bugreport@myseotoolbox.com">bugreport@myseotoolbox.com</a></p>

            <h2>Please <span role="img" aria-label="praying hands">&#x1f64f;</span></h2>
            <p>Remember to include the link and a short description of how you encountered the error </p>

        </ErrorContainer>
    )
};


export default SomethingWentWrong;
