import Avatar from "@material-ui/core/Avatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from "reactstrap";
import { LOGOUT_SUCCESS } from "../../reducers/login";
import apiFetch from '../util/fetch-utils';
import "./index.css";

export const AccountAvatarMenu = () => {

    const [modalLogoutWindowOpen, setModalLogoutWindowOpen] = useState(false);
    const login = useSelector((state) => state.login);
    const history = useHistory();
    const dispatch = useDispatch();
    let loggedIn = login.userDetails.isLoggedIn;

    const LoginLink = () => <NavLink onClick={gotoLogin}
                                     href={""}>{loggedIn ? "Log out " + login.userDetails.username : "Log in"} </NavLink>;

    const UserAvatar = () => {

        const [anchorEl, setAnchorEl] = React.useState(null);


        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const onMyAccount = () => {
            history.push("/account");
            handleClose();
        };

        const onMySubscription = () => {
            history.push("/account/subscription");
            handleClose();
        };

        const onLogout = () => {
            if (loggedIn) toggleLogoutModal();
            handleClose();
        };
        return (
            <>
                <ButtonBase onClick={handleClick}>
                    <Avatar/>
                </ButtonBase>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={onMyAccount}>My account</MenuItem>
                    <MenuItem onClick={onMySubscription}>Subscription</MenuItem>
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>

            </>
        );
    };

    const logout = () => {
        apiFetch("/api/logout", {
            method: 'POST'
        }).then(value => {
            if (value.status === 200) {
                setModalLogoutWindowOpen(false);
                dispatch({type: LOGOUT_SUCCESS});
                window.location.hash = "login";
            }
        });
    };

    const gotoLogin = (event) => {
        event.preventDefault();
        history.push("/login")
    };

    const toggleLogoutModal = () => {
        setModalLogoutWindowOpen(!modalLogoutWindowOpen);
    };

    return (
        <div>
            {loggedIn ? <UserAvatar/> : <LoginLink/>}
            <Modal isOpen={modalLogoutWindowOpen} toggle={toggleLogoutModal}>
                <ModalHeader toggle={toggleLogoutModal}>Confirm Logout</ModalHeader>
                <ModalBody>
                    Are you sure you want to logout?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={logout}>Yes</Button>{' '}
                    <Button color="secondary" onClick={toggleLogoutModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AccountAvatarMenu;
