import React from "react";
import {Button, ButtonGroup, Col} from 'reactstrap';
import {connect} from "react-redux";
import {CURRENT_MONITORED_SELECT_SNAPSHOT} from "../../reducers/currentMonitoredUri";
import {dayOf, formatDateOnly, getDateAtIndex, getIndexForDate} from "./commons";

import {MdChevronLeft, MdChevronRight, MdSkipNext, MdSkipPrevious} from "react-icons/md";


class Buttons extends React.Component {
    render() {
        return (
            <Col className={"text-center"}>
                <ButtonGroup>
                    <Button title="Select previous change in the crawled page" color="primary"
                            disabled={!this.canSelectPrevChange()} onClick={this.prevChange}><MdSkipPrevious/></Button>

                    <Button title="Select previous crawl" color="primary"
                            disabled={!this.canDecrement()} onClick={this.prevCrawl}><MdChevronLeft/></Button>

                    <Button title="Select next crawl" color="primary"
                            disabled={!this.canIncrement()} onClick={this.nextCrawl}><MdChevronRight/></Button>

                    <Button title="Select next change in the crawled page" color="primary"
                            disabled={!this.canSelectNextChange()} onClick={this.nextChange}><MdSkipNext/></Button>
                </ButtonGroup>

            </Col>
        );
    }

    prevCrawl = () => {
        this.selectSnapshot(this.getCurrentIndex() + 1);
    };

    prevChange = () => {

        let prevChange = this.getPrevChange();

        if (prevChange) {
            const currentIndex = getIndexForDate(prevChange.crawlDate);
            this.selectSnapshot(currentIndex);
        }
    };

    canSelectPrevChange = () => {
        let prevChange = this.getPrevChange();
        return prevChange && getIndexForDate(prevChange.crawlDate) < this.props.numDaysHistory - 1;
    };

    nextChange = () => {

        let nextChange = this.getNextChange();

        if (nextChange) {
            const currentIndex = getIndexForDate(nextChange.crawlDate);
            this.selectSnapshot(currentIndex);
        } else {
            this.selectSnapshot(getIndexForDate(this.props.lastSnapshotDate))
        }
    };

    canSelectNextChange = () => {
        return this.getCurrentIndex() !== getIndexForDate(this.props.lastSnapshotDate);
    };

    nextCrawl = () => {
        this.selectSnapshot(this.getCurrentIndex() - 1);
    };

    getPrevChange() {
        return this.getFilteredPageChanges()
            .slice()
            .reverse()
            .find(it => dayOf(it.crawlDate) < dayOf(this.props.curSnapshotDate));
    }

    getNextChange() {
        return this.getFilteredPageChanges()
            .find(it => dayOf(it.crawlDate) > dayOf(this.props.curSnapshotDate));
    }

    getFilteredPageChanges() {
        // console.log(this.props.pageChanges)
        return this.props.pageChanges.filter(
            it => {
                return it.changes.some(change => ["title", "h1s", "metaDescriptions", "redirectChainElements"].includes(change.key))
            });
    }

    canDecrement = () => {
        return this.getCurrentIndex() < this.props.numDaysHistory - 1;
    };

    canIncrement = () => {
        return getIndexForDate(this.props.curSnapshotDate) > 0;
    };

    selectSnapshot(dateIndex) {
        let newDate = formatDateOnly(getDateAtIndex(dateIndex));
        this.props.dispatch({type: CURRENT_MONITORED_SELECT_SNAPSHOT, curSnapshotDate: newDate})
    }

    getCurrentIndex() {
        return getIndexForDate(this.props.curSnapshotDate);
    }
}


function mapDispatchToProps(dispatch) {
    return {dispatch};
}


function mapsStateToProps(state) {
    return {...state.currentMonitoredUri};
}

export default connect(mapsStateToProps, mapDispatchToProps)(Buttons);
