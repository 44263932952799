import {FETCH_MONITORED_URI_FAILURE, FETCH_MONITORED_URI_STARTED, FETCH_MONITORED_URI_SUCCESS, MONITORED_URI_DELETED, MONITORED_URI_REFERENCE_VALUE_UPDATED} from "../actions/monitoredUri";


const initialState = {
    tableData: [],
    fetching: false,
    lastFetchSuccessful: false,
    page: {
        number: 0, size: 0, totalElements: 0, totalPages: 0
    }
};


export default (state = initialState, action) => {


    switch (action.type) {

        case MONITORED_URI_REFERENCE_VALUE_UPDATED:
            const monitoredUriIndex = state.tableData.findIndex(value => value.id === action.monitoredUriId);
            if (monitoredUriIndex !== -1) {
                Object.assign(state.tableData[monitoredUriIndex].recommendation, action.newValue);
                return Object.assign({}, state, {tableData: state.tableData.slice(0)});
            }
            return state;

        case FETCH_MONITORED_URI_STARTED:
            state.fetching = true;
            state.lastQueryString = action.payload;
            return Object.assign({}, state);

        case FETCH_MONITORED_URI_SUCCESS:
            return Object.assign({}, state, {...action.payload, fetching: false, lastFetchSuccessful: true});

        case FETCH_MONITORED_URI_FAILURE:
            return Object.assign({}, state, {fetching: false, lastFetchSuccessful: false});

        case MONITORED_URI_DELETED:
            return Object.assign({}, state, {
                tableData: state.tableData
                    .filter(it => it.id !== action.id)
            });
        default:
            return state
    }
}

