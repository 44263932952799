import React from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import { Col } from "reactstrap";
import { fetchWorkspaceStats } from "../../actions/stats";
import { DashboardContainer, DashboardRow, DashboardTitle } from "./DashboardComponents";
import IndexStatsWidget from "./IndexStatsWidget";
import RecommendationTracker from "./RecommendationTracker";


class DashboardSimple extends React.Component {


    getWorkspaceNumber() {
        return this.props.match.params.workspaceNumber;
    }

    componentDidMount() {
        this.props.fetchWorkspaceStats(this.getWorkspaceNumber())
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.getWorkspaceNumber() !== prevProps.match.params.workspaceNumber)
            this.props.fetchWorkspaceStats(this.props.match.params.workspaceNumber);
    }


    render() {
        let cur = this.props.workspaces.current;

        return (
            <DashboardContainer>
                <DashboardRow>
                    <Col>
                        <DashboardTitle>Dashboard: <strong>{cur.name}</strong></DashboardTitle>
                    </Col>
                </DashboardRow>
                <DashboardRow>
                    <Col sm={12} md={6}>
                        <RecommendationTracker/>
                    </Col>
                    <Col sm={12} md={6}>
                        <IndexStatsWidget/>
                    </Col>
                </DashboardRow>
            </DashboardContainer>
        );
    }


}


function mapsStateToProps(state) {
    return {"workspaces": state.workspaces, ...state.stats};
}


function mapDispatchToProps(dispatch) {
    return {
        fetchWorkspaceStats: function (workspaceNumber) {
            dispatch(fetchWorkspaceStats(workspaceNumber));
        }
    };
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(DashboardSimple));
