import React, {useEffect, useState} from "react";
import {Button} from "reactstrap";
import {makeStyles} from "@material-ui/core";
import {AnalyticsSettingsModal} from "./AnalyticsSettingsModal";
import {AnalyticsViewDetails} from "../AnalyticsViewDetails";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";


const useStyles = makeStyles({
    table: {
        minWidth: 80,
        "margin-bottom": "1em"
    }, tableHead: {
        fontWeight: 800,
        width: "10em"
    }
});

export default function GoogleAnalyticsSelect(props) {

    const classes = useStyles();
    const location = useLocation();
    const [isModalOpenState, setModalOpenState] = useState(false);
    const userDetails = useSelector(state => state.login.userDetails);


    useEffect(() => {
        if (location.search.includes("selectAnalyticsViewOpen=true")) {
            setModalOpenState(true);
        }
    }, [location.search])


    let settings = props.ws.analyticsSettings || {};

    const userIsWorkspaceOwner = props.ws.ownerName === userDetails.username;
    return (
        <>
            <AnalyticsViewDetails settings={settings} classes={classes}/>
            <Button disabled={!userIsWorkspaceOwner} color="primary"
                    onClick={() => setModalOpenState(true)}>Select</Button>{' '}
            <Button disabled={!userIsWorkspaceOwner} outline color="danger"
                    onClick={() => props.setAnalyticsView({})}>Remove</Button>

            <AnalyticsSettingsModal
                userIsWorkspaceOwner={userIsWorkspaceOwner}
                analyticsSettings={props.ws.analyticsSettings}
                workspaceNumber={props.ws.seqNumber}
                setAnalyticsView={props.setAnalyticsView}
                workspaceWebsiteUrl={props.ws.websiteUrl}
                userDetails={userDetails}
                isOpen={isModalOpenState} toggle={toggle}/>
        </>
    );

    function toggle() {
        setModalOpenState(!isModalOpenState);
    }
}