import {toaster} from "evergreen-ui";

export default function apiFetch(input, init) {
    const responsePromise = fetch(input, {...init, credentials: "same-origin"});

    return responsePromise.then(value => {

        const errorDescription = 'Request to ' + value.url + " returned: " + value.status + " " + value.statusText;

        //Warning
        // 401 is used in /api/google-analytics/account-summaries?tokenName=
        // 404 is used in /archive-api/page?uri=https://myseotoolbox.com&date=2020-05-09
        // 403 is used by login error

        // any login error
        if (value.status === 403) {
            window.location.hash = "login";
        }
        if (value.status >= 500) {
            console.error("Fetch error! (", value.status + ")", value);
            toaster.danger("Unable to fetch required data", {
                description: errorDescription,
                duration: 20
            });
        }

        return value;
    });

}

export function dumpErrorOnCustomer(errorDescription) {
    toaster.danger("Unable to fetch required data", {
        description: "" + errorDescription,
        duration: 20
    });
}

export function ensureStatusOk(response) {
    const errorDescription = 'Request to ' + response.url + " returned: " + response.status + " " + response.statusText;

    if (!response.ok) {
        throw new Error(errorDescription);
    }
    return response;
}