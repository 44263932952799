import React, {useEffect, useState} from "react";
import apiFetch from "../../util/fetch-utils";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Select from "react-select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {makeStyles, Tooltip} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {AnalyticsToken} from "../AnalyticsAccount";
import AddIcon from '@material-ui/icons/Add';
import {useHistory, useLocation} from "react-router";
import {NoGAConnected, NoViewsAvailable} from "./SelectSubModules";

const useStyles = makeStyles((theme) => ({
    invalidTokenFeedback: {
        width: "100%",
        "margin-top": "0.25rem",
        "font-size": "80 %",
        color: "#dc3545",
    },
    "noOptionsMessage": {
        color: "hsl(0,0%,60%)",
        padding: "8px 12px",
        textAlign: "center",
        boxSizing: "border-box"
    }, button: {
        marginLeft: theme.spacing(1)
    }, accountSelect: {
        width: "100%"
    }
}));

export const AnalyticsSettingsModal = (props) => {
    const [currentWorkspace, setCurrentWorkspace] = useState(false);
    const [analyticsToken, setAnalyticsToken] = useState({});
    const [analyticsView, setAnalyticsView] = useState(null);
    const [analyticsViews, setAnalyticsViews] = useState([]);
    const [accountSummariesLoading, setAccountSummariesLoading] = useState(false);
    const [filters, setFilters] = useState({workspaceUrlActive: true});
    const [loadingError, setLoadingError] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const analyticsSettings = props.analyticsSettings || {};

    const classes = useStyles();

    if (props.userIsWorkspaceOwner) loadDefaults();

    let tokenName = analyticsToken.value;

    useEffect(() => {
        if (!props.userDetails.analyticsTokens) return;

        const newTokenName = getParam(location.search, "newTokenName");
        const t = props.userDetails.analyticsTokens
            .find(token => token.name === newTokenName);
        if (t) {
            setAnalyticsToken(renderToken(t));
        }

    }, [location, props.userDetails.analyticsTokens])

    useEffect(() => {
        let url = "/api/google-analytics/account-summaries?tokenName=" + tokenName;

        if (!tokenName) return;

        setAccountSummariesLoading(true);
        setAnalyticsViews([]);
        setLoadingError(null);

        apiFetch(url)
            .then(res => {
                if (res.status !== 200) throw new Error();
                return res.json();
            })
            .then(
                (result) => {
                    let accountSummaries =
                        result
                            .flatMap(account => account.webProperties
                                .flatMap(property => property.views
                                    .map(view => {
                                        return {"account": account, "property": property, "view": view}
                                    })));

                    let views = accountSummaries
                        .map(item => {
                            return {
                                "websiteUrl": item.property.websiteUrl,
                                "tokenId": analyticsToken.id,
                                "tokenName": tokenName,
                                "value": item.view.id,
                                "viewName": item.view.name,
                                "analyticsAccountOwner": item.account.ownerName,
                                "label": <>
                                    <Chip variant="outlined" size="small" label={item.view.id}/>
                                    {" "}
                                    {item.view.name}
                                </>
                            }
                        });

                    setAnalyticsViews(views);
                }, (error) => {
                    setLoadingError(error);
                }
            ).finally(() => {
            setAccountSummariesLoading(false);
        })
    }, [tokenName, analyticsToken.id]);

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size={"xl"}>
            <ModalHeader toggle={props.toggle}>Select Google Analytics view</ModalHeader>
            <ModalBody>
                <FormGroup row inline>
                    <Label for="value" sm={3}>Analytics Account</Label>
                    <Col sm={9}>
                        <Grid wrap={"nowrap"} container direction="row" justify="flex-start" alignItems="center">
                            <Select className={classes.accountSelect}
                                    onChange={data => onTokenChange(data)}
                                    value={analyticsToken}
                                    components={{NoOptionsMessage: NoGAConnected(classes, addAnalyticsAccount)}}
                                    options={getAccounts()}/>
                            <Tooltip title="Add Analytics accounts">
                                <IconButton color="primary" onClick={addAnalyticsAccount}>
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Manage Analytics accounts">
                                <IconButton color="primary" onClick={gotoManageAnalyticsAccounts}>
                                    <MoreHorizIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {loadingError && InvalidAuthAlert(classes.invalidTokenFeedback)}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="value" sm={3}>Analytics View</Label>
                    <Col sm={9}>

                        <FormControlLabel
                            control={
                                <Switch checked={filters.workspaceUrlActive} onChange={handleFilterChange}
                                        name="workspaceUrlActive"
                                        color="primary"/>
                            }
                            label="Filter by Website URL in this Workspace"/>

                        <Select isClearable={true}
                                isLoading={accountSummariesLoading}
                                value={analyticsView}
                                components={{NoOptionsMessage: NoViewsAvailable(classes, analyticsViews, setFilters)}}
                                onChange={data => {
                                    setAnalyticsView(data);
                                }}
                                options={filteredViews(analyticsViews)}/>

                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!analyticsView} onClick={onOk}>Ok</Button>
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>

    );


    function getParam(query, paramName) {
        if (!query.includes("?") || !query.includes(paramName)) return;
        return query.slice(1)
            .split("&")
            .map(value => value.split("="))
            .find(value => value[0] === paramName)[1];
    }

    function addAnalyticsAccount() {
        window.location.href = buildRedirectUrl();
    }

    function buildRedirectUrl() {
        let curUrl = new URL(window.location.href);
        let curHash = curUrl.hash;
        curUrl.hash = curUrl.hash + (curHash.includes("?") ? "&" : "?") + "selectAnalyticsViewOpen=true";
        return "/api/google-analytics/authorize?redirectUrl=" + encodeURIComponent(curUrl.toString());
    }

    function gotoManageAnalyticsAccounts() {
        history.push("/account/google-analytics");
    }


    function onTokenChange(data) {
        setAnalyticsView(null);
        setLoadingError(false);
        setAnalyticsToken(data);
    }


    function filteredViews(analyticsViews) {
        return analyticsViews.filter(view => webPropertiesFilter(view));
    }


    function loadDefaults() {
        if (getParam(location.search, "newTokenName")) return;

        if (currentWorkspace !== props.workspaceNumber) {
            setAnalyticsToken(renderToken({
                name: analyticsSettings.tokenName,
                id: analyticsSettings.tokenId,
                analyticsAccountOwner: analyticsSettings.analyticsAccountOwner
            }));
            setAnalyticsView({value: analyticsSettings.viewId});
            setCurrentWorkspace(props.workspaceNumber);
        }

        //Separate condition as it needs the views to load asynchronously
        if (analyticsView && analyticsView.value && !analyticsView.label) {
            let def = analyticsViews.find(value => {
                return value.value === analyticsSettings.viewId
            });
            if (def) {
                setAnalyticsView(def);
            }
        }
    }

    function webPropertiesFilter(curView) {
        if (!filters.workspaceUrlActive || !props.workspaceWebsiteUrl) return true;
        if (!curView || !curView.websiteUrl) return false;
        try {
            let workspaceWebsiteUrl = new URL(props.workspaceWebsiteUrl);
            let googleWebsiteUrl = new URL(curView.websiteUrl);
            return normalizeUrl(googleWebsiteUrl.hostname) === normalizeUrl(workspaceWebsiteUrl.hostname);
        } catch (error) {
            return true;
        }
    }

    function normalizeUrl(url) {
        return url.replace("www.", "");
    }

    function handleFilterChange(event) {
        setFilters({...filters, [event.target.name]: event.target.checked});
    }

    function getAccounts() {
        if (!props.userDetails.analyticsTokens) return [];
        return props.userDetails.analyticsTokens.map(token => renderToken(token));
    }

    function onOk() {
        props.setAnalyticsView(analyticsView);
        props.toggle();
    }

};

function renderToken(token) {
    return {
        value: token.name, label: <AnalyticsToken token={token}/>, id: token.id
    }
}

AnalyticsSettingsModal.defaultProps = {
    analyticsSettings: {},
    userDetails: {
        analyticsTokens: []
    }
};

const InvalidAuthAlert = (style) => <div className={style}>Invalid Analytics Authorization.</div>;
