import React, {Component} from "react";


import TextareaAutosize from "react-autosize-textarea";

import {IoMdAddCircleOutline, IoMdRemoveCircleOutline} from "react-icons/io";
import "./style.css"
import {Button, Card, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";


export class EditableFieldList extends Component {


    addRecommendation = () => {
        this.updateRecommendation(prev => {
            if (this.props.currentValue && this.props.currentValue.length > prev.length) {
                prev.push(this.props.currentValue[prev.length]);
            } else {
                prev.push("");                
            }
            return prev;
        });
    };

    removeRecommendation = () => {
        this.updateRecommendation(prev => {
            if (prev.length > 1) {
                prev.pop();
            } else {
                prev = null;
            }
            return prev;
        });
    };

    onRecommendationChange = (index, value) => {
        this.updateRecommendation(prev => {
            prev[index] = value;
            return prev;
        });
    };

    updateRecommendation(editFunction) {
        const newVal = editFunction(this.props.recommendation !== null ? this.props.recommendation.slice(0) : []);
        this.props.onChange(newVal);
    }

    render() {
        return (
            <Card body>
                <Form className={"field-editor-form"}>
                    <FormGroup>
                        <CurrentValueHeader/>
                        <CurrentValueBody value={this.props.currentValue}/>
                    </FormGroup>
                    <FormGroup>
                        <RecommendationHeader add={this.addRecommendation} remove={this.removeRecommendation}/>
                        <RecommendationBody value={this.props.recommendation} onChange={this.onRecommendationChange}/>
                    </FormGroup>
                </Form>
            </Card>
        );
    }
}

function CurrentValueHeader() {
    return <Label for="currentValue">Current Value</Label>;
}

function CurrentValueBody(props) {
    const values = props.value === null ? [] : props.value;

    return (
        <ol className="list-group list-group-flush rounded-list">
            {values.map((value, index) => (
                <li key={index} className="list-group-item">
                    <div className="rounded-list-text">{value}</div>
                </li>
            ))}
        </ol>
    );
}

function RecommendationHeader(props) {
    return <Row id="recommendationLabel">
        <Col sm={9}>
            <Label for="recommendation">Recommended value</Label>
        </Col>
        <Col sm={3}>
            <Button onClick={props.add} className={"recommended-button"}
                    color={"success"}
                    title="Add recommendation">
                <div className={"center-vertically"}>
                    <IoMdAddCircleOutline size={27}/>
                </div>
            </Button>
            <Button onClick={props.remove} className={"recommended-button"}
                    color={"danger"}
                    title="Remove recommendation">
                <div className={"center-vertically"}>
                    <IoMdRemoveCircleOutline size={27}/>
                </div>
            </Button>
        </Col>
    </Row>;
}

function RecommendationBody(props) {
    let recommendation = props.value;
    if (recommendation !== null) {
        if (recommendation.length === 0) recommendation = [""];
        return (
            <ol className="list-group list-group-flush rounded-list">
                {recommendation.map((value, index) => (
                    <li key={index} className="list-group-item">
                        <RecommendationEditableField index={index} value={value} onChange={props.onChange}/>
                    </li>
                ))}
            </ol>
        );
    } else {
        return (
            <Row>
                <Col>
                    <Input disabled value="No recommendation provided"/>
                </Col>
            </Row>
        );
    }
}

class RecommendationEditableField extends Component {

    handleChange = ({target}) => {
        this.props.onChange(this.props.index, target.value)
    };

    render() {
        return (
            <TextareaAutosize onChange={this.handleChange}
                              value={this.props.value}
                              className={'form-control editor edit-text'}/>
        );
    }
}

