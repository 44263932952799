import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchWorkspaceById } from "../../actions/workspaces";

const WorkspacePolling = ({ workspaceId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (workspaceId) { // Only proceed if workspaceId is defined
      dispatch(fetchWorkspaceById(workspaceId));

      const pollingInterval = setInterval(() => {
        dispatch(fetchWorkspaceById(workspaceId));
      }, 10000);

      return () => {
        clearInterval(pollingInterval);
      };
    }
  }, [dispatch, workspaceId]); 

  return null;
};

WorkspacePolling.propTypes = {
  workspaceId: PropTypes.string // No longer required
};

export default WorkspacePolling;
