import React from "react";
import Grid from "@material-ui/core/Grid";
import {Avatar, Card} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";


const useStyles = makeStyles((theme) => ({
    avatarBox: {
        "display": "flex",
        "textAlign": "center",
        "alignItems": "center",
        "flexDirection": "column",

    }, avatarIcon: {
        "width": "80px",
        "height": "80px",
        "marginBottom": "1rem"
    }
}));

export const UserDetails = (props) => {

    const login = useSelector((state) => state.login.userDetails);
    const classes = useStyles();

    return <Grid item xs={12} md={4}>
        <Card>
            <CardContent>
                <Box className={classes.avatarBox}>
                    <Avatar className={classes.avatarIcon}/>
                    <Typography variant="body1">{login.username}</Typography>
                </Box>
            </CardContent>
        </Card>
    </Grid>
};