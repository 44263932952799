import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';

import './style.css'

const jsdiff = require('diff');


class DiffPopover extends Component {


    constructor(props) {
        super(props);


        this.toggle = this.toggle.bind(this);
        this.state = {
            differencesPopoverOpen: false,
            ...this.props
        };

        this.componentID = "ID" + DiffPopover._componentID++;
    }

    toggle() {
        this.setState({
            differencesPopoverOpen: !this.state.differencesPopoverOpen
        });
    }


    render() {


        let differencesButton = null;
        let diff = null;


        if (!this.state.diffDisabled) {
            // console.log("Diffing", this.props);
            this.diffFunction = DiffPopover.fnMap[this.props.type](this.props.inputA || "", this.props.inputB || "", {ignoreWhitespace: true});

            diff = this.diffFunction.map((part, index) => {

                let spanClass = part.added ? 'added' : part.removed ? 'removed' : '';

                return (
                    <span key={index} className={spanClass}>{part.value}</span>
                );

            });


            if (this.inputAreDifferent()) {

                // console.debug("Field " + this.props.name + " different: (" + this.inputAreDifferent() + ")",
                //     "\nRecc) '" + this.props.inputA + "' :", typeof this.props.inputA,
                //     "\nActu) '" + this.props.inputB + "' :", typeof this.props.inputB);

                differencesButton = (
                    <span className={"allow-overflow"}>
                        <Badge color="info" id={"Badge" + this.componentID} onClick={this.toggle}>
                            Differences
                        </Badge>

                        <Modal className={"very-large"} isOpen={this.state.differencesPopoverOpen} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>{this.props.name + " details"}</ModalHeader>
                            <ModalBody>
                                <Table size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th className={"rowHeader"}>Recommended</th>
                                            <td><pre>'{this.props.inputA}'</pre></td>
                                        </tr>
                                        <tr>
                                            <th className={"rowHeader"}>Current</th>
                                            <td>
                                                <pre>
                                                    {this.props.inputB !== null ?
                                                        "'" + this.props.inputB + "'" :
                                                        "none"}
                                                </pre>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Differences</th>
                                            <td><pre>'{diff}'</pre></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>

                                <Table borderless size="sm">
                                    <tbody>
                                    <tr><td><h6>Legend</h6></td></tr>
                                    <tr className={'added'}><td>Added text</td></tr>
                                    <tr className={'removed'}><td>Missing text</td></tr>
                                    </tbody>
                                </Table>
                            </ModalFooter>
                        </Modal>


                    </span>
                )
            }
        }
        return differencesButton;
    };

    inputAreDifferent() {
        return typeof this.props.inputA === "string" && this.props.inputA !== this.props.inputB;
    }
}


DiffPopover._componentID = 0;

DiffPopover.fnMap = {
    'chars': jsdiff.diffChars,
    'words': jsdiff.diffWords,
    'sentences': jsdiff.diffSentences,
    'json': jsdiff.diffJson
};

DiffPopover.defaultProps = {
    inputA: '',
    inputB: '',
    type: 'words'
};

DiffPopover.propTypes = {
    inputA: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputB: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.oneOf(['chars', 'words', 'sentences', 'json'])
};


export default DiffPopover;
