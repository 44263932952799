import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {StarIcon} from "evergreen-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {getPriceIdFrom} from "../AccountDetails/Subscription/subscriptionUtils";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    }, productCard: ({standalone}) => ({
        position: "relative",
        minHeight: standalone ? "0" : "480px"
    }), featuredProductCard: ({standalone}) => ({
        position: "relative",
        minHeight: standalone ? "0" : "505px"
    }),
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    }, pricingRoot: {
        display: "grid",
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    freeTrialBlock: ({standalone}) => ({
        minHeight: standalone ? "0" : "1.8rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
    }),
    cardActionsContainer: {
        position: "relative"
    },
    cardActions: ({standalone}) => ({
        position: standalone ? "relative" : "absolute",
        bottom: ".8rem",
        width: "100%"
    })
}));
export default function ProductCard({product, standalone, priceInterval, cardSizes, action}) {
    const classes = useStyles({standalone});
    const history = useHistory();
    const billingInformation = useSelector((state) => state.login.userDetails.billingInformation);
    const isLoggedIn = useSelector(state => state.login.userDetails.isLoggedIn);


    return <Grid item className="productCard" key={product.name} {...cardSizes}>
        <Card className={isFeatured(product) ? classes.featuredProductCard : classes.productCard}>
            <CardHeader
                title={product.name.replace(/plan/g, "")}
                subheader={uiPropertyFor(product, "subheader")}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{align: 'center'}}
                action={uiPropertyFor(product, "actionIcon")}
                className={classes.cardHeader}
            />
            <CardContent>
                <div className={classes.pricingRoot}>
                    <div className={classes.cardPricing}>
                        <Typography className="priceContainer" component="h3" variant="h3" color="textPrimary">
                            {"£" + getPriceValue(product)}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {"/" + shortenInterval(priceInterval)}
                        </Typography>
                    </div>
                    <FreeTrial product={product}/>
                </div>
                <ul>
                    {product.features.map((feature, i) => (
                        <Typography component="li" variant="subtitle1" align="center" key={i}>
                            {feature.label}
                        </Typography>
                    ))}
                </ul>
            </CardContent>
            <CardActions className={classes.cardActions + " cardAction"}>
                <CardAction actionOverride={action}/>
            </CardActions>
        </Card>
    </Grid>;

    function CardAction({actionOverride}) {
        if (actionOverride) return actionOverride;

        const alreadyOwnedPlan = isAlreadyOwnedPlan(product);
        return <Button fullWidth
                       onClick={() => history.push("/checkout?priceId=" + getPriceId(product))}
                       variant={uiPropertyFor(product, "buttonVariant")}
                       disabled={alreadyOwnedPlan}
                       color={isCancellingPlanOption() ? "secondary" : "primary"}>
            {getActionText(product, alreadyOwnedPlan)}
        </Button>

        function getActionText(product, isAlreadyOwnedPlan) {
            if (isAlreadyOwnedPlan) return "Your current plan";
            if (isCancellingPlanOption()) return "Cancel your subscription";
            return "Select";
        }

        function isEmptyPlan() {
            return !isLoggedIn || !billingInformation || !billingInformation.subscription || !billingInformation.subscription.plan;
        }

        function isCancellingPlanOption() {
            if (isEmptyPlan()) return false;
            const priceValue = getPriceValue(product);
            return priceValue === 0 && billingInformation.subscription.plan.price > 0;
        }

        function isAlreadyOwnedPlan(product) {
            if (isEmptyPlan()) return false;
            const productPriceId = getPriceId(product);
            const currentCustomerPriceId = getPriceIdFrom(billingInformation);
            return productPriceId === currentCustomerPriceId;
        }
    }

    function FreeTrial({product}) {

        let freeTrialText = "";

        const trialPeriodDays = getPriceForProduct(product).recurring.trialPeriodDays;
        if (trialPeriodDays) {
            freeTrialText = trialPeriodDays + " days free trial";
        }

        return <div className={classes.freeTrialBlock}>
            <Typography variant="subtitle1" color="textSecondary">
                {freeTrialText}
            </Typography>
        </div>;


    }

    function getPriceValue(product) {
        let price = getPriceForProduct(product);
        if (!price) return 0;
        return (price.unitAmountDecimal / 100);
    }

    function getPriceId(product) {
        return getPriceForProduct(product).id;
    }

    function getPriceForProduct(product) {
        return product.prices.find(price => price.recurring.interval === priceInterval);
    }

    function shortenInterval(priceInterval) {
        return priceInterval === "month" ? "mo" : "year";
    }

    function uiPropertyFor(product, property) {
        if (!staticProductsUiProperties[product.featuresGroup] ||
            !staticProductsUiProperties[product.featuresGroup][property]) return staticProductsUiProperties.default[property];
        return staticProductsUiProperties[product.featuresGroup][property];
    }

    function isFeatured(product) {
        return uiPropertyFor(product, "featured");
    }

}


const staticProductsUiProperties = {
    "default": {buttonVariant: 'outlined'},
    "pro": {
        featured: true,
        buttonVariant: 'contained',
        subheader: "Most popular",
        actionIcon: <StarIcon/>
    }
}