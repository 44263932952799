export const RECOMMENDATION_POST_STARTED = "RECOMMENDATION_POST_STARTED";
export const RECOMMENDATION_POST_ENDED = "RECOMMENDATION_POST_ENDED";

const initialState = {
    isPostInProgress: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECOMMENDATION_POST_STARTED:
            return Object.assign({}, state, {isPostInProgress: true});
        case RECOMMENDATION_POST_ENDED:
            return Object.assign({}, state, {isPostInProgress: false});
        default:
            return state
    }
}


