import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Col, FormGroup, Row} from "reactstrap";

import "./WorkspaceSelectionPage.css"
import {Link} from "react-router-dom";
import {generateUrlForWorkspace} from "./common";
import Fuse from "fuse.js"
import apiFetch from "../util/fetch-utils";
import {toaster} from "evergreen-ui";
import {WORKSPACE_CREATED_EVENT} from "../../actions/workspaces";
import {withRouter} from "react-router";
import {CountryFlag} from "./CountryFlag";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from "@material-ui/core/Button";
import {AddCircle} from "@material-ui/icons";

const MAX_WORKSPACE_PER_PAGE = 16;


const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        marginLeft: "1rem",
        flex: 1
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
};


class WorkspaceSelectionPage extends React.Component {
    createWorkspace = () => {
        apiFetch('/api/workspaces', {
            method: 'POST',
            headers: {"Content-Type": "application/json"}
        }).then(response => {

            if (response.status === 201) {

                response.json().then(responseJson => {
                    this.props.dispatch({type: WORKSPACE_CREATED_EVENT, payload: responseJson});
                    this.props.history.push(generateUrlForWorkspace(responseJson.seqNumber) + "/admin");
                });
            } else {
                toaster.danger("Unable to create workspace.", {
                    description: response.status + " " + response.statusText,
                    duration: 10
                })
            }

        });
    };

    constructor(props) {
        super(props);
        this.state = {filter: ""}
    }


    handleChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };


    render() {

        if (!this.props.workspaces.fetched) return <p>Fetching Workspaces...</p>;

        let filteredWorkspaces = this.props.workspaces.data;


        if (this.state.filter.length > 0) {
            const options = {
                shouldSort: true,
                tokenize: true,
                includeScore: true,
                threshold: 0.2,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 0,
                keys: [
                    "name"
                ]
            };
            const fuse = new Fuse(this.props.workspaces.data, options); // "list" is the item array
            const result = fuse.search(this.state.filter);

            filteredWorkspaces =
                result.map(element => element.item);

        }

        let searchPlaceholder = "Search workspace";

        const {classes} = this.props;
        return (
            <div>

                <Container>
                    <h4>Select workspace </h4>
                    <FormGroup>
                        <Row className="toolbar-container">
                            <Col md={9}>
                                <Paper className={classes.root}>

                                    <InputBase
                                        type="search" name="filter" placeholder={searchPlaceholder}
                                        value={this.state.filter}
                                        className={classes.input}
                                        onChange={this.handleChange}
                                        inputProps={{'aria-label': 'search google maps'}}
                                    />

                                    <IconButton className={classes.iconButton} aria-label="search">
                                        <SearchIcon/>
                                    </IconButton>
                                </Paper>
                            </Col>
                            <Col md={3}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    alt="Create new workspace" onClick={this.createWorkspace}>
                                    <AddCircle/>&nbsp;Add Workspace
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Showing <b>{Math.min(MAX_WORKSPACE_PER_PAGE, this.props.workspaces.data.length)}</b> of <b>{this.props.workspaces.data.length}</b> workspaces. Use '{searchPlaceholder}' to
                        refine.
                    </Typography>
                    <br/>
                </Container>


                <Container>
                    <Grid container spacing={4}>

                        {filteredWorkspaces
                            .slice(0, Math.min(MAX_WORKSPACE_PER_PAGE, filteredWorkspaces.length))
                            .map((curWs, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={4} md={3}>
                                        <Card>
                                            <CardHeader
                                                title={limitLen(curWs.name, 22)}
                                                subheader={curWs.websiteUrl}
                                                avatar={
                                                    <LightAvatar aria-label="country code">
                                                        <CountryFlag countryCode={curWs.countryCode}/>
                                                    </LightAvatar>
                                                }
                                            />
                                            <CardContent>
                                                {/*{curWs.websiteUrl}*/}
                                            </CardContent>
                                            <CardActions>
                                                <LargeButton
                                                    component={Link}
                                                    variant="contained" to={generateUrlForWorkspace(curWs.seqNumber)}>Open</LargeButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Container>

            </div>);
    }


}

const LightAvatar = styled(Avatar)`
  background-color: #f1eded !important;
`;

function limitLen(str, maxLen) {
    if (!str) return "";
    return str.substring(0, Math.min(str.length, maxLen));
}


const LargeButton = styled(Button)`
    margin-left: 5% !important;
    margin-bottom: 10px !important;
    width: 90%;
`;


function mapsStateToProps(state) {
    return {"login": state.login, "workspaces": state.workspaces};
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}


export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(withStyles(styles)(WorkspaceSelectionPage)));

