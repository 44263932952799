import React from 'react';
import { Box, Typography, LinearProgress, withStyles, makeStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 5,
        position: 'relative',
        flexGrow: 1,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    progressBar: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
    },
    progressText: {
        minWidth: 35,
        whiteSpace: 'nowrap',
    },
}));

function Progress({ progress }) {
    const classes = useStyles();

    return (
        <Box className={classes.progressContainer}>
            <BorderLinearProgress 
                variant="determinate" 
                value={progress} 
                className={classes.progressBar} 
            />
            <Typography variant="body2" className={classes.progressText}>
                {`${Math.round(progress)}%`}
            </Typography>
        </Box>
    );
}

export default Progress;
